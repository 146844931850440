import React from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom'

export default function Videosection() {
    
   const location = useLocation();



    const videos=[
        {
            title:'pregnancy_care_delivery',
            name:'Pregnancy care and delivery',
            links:[
                'https://www.youtube.com/embed/yClANco6BKQ',
                'https://www.youtube.com/embed/xsB5_dyqfHA',
                'https://www.youtube.com/embed/isUlyGAChqE',
                'https://www.youtube.com/embed/Q2INFJMSjDw',
                'https://www.youtube.com/embed/m_woj2aQS04'
            ]
        },
        {
            title:'gynaecologys',
            name:'Gynaecologys',
            links:[
                'https://www.youtube.com/embed/53aAlNzWoIc',
                'https://www.youtube.com/embed/yClANco6BKQ',
                'https://www.youtube.com/embed/Z7fF26MywDg',
                'https://www.youtube.com/embed/ivU8soAEHqw',
                'https://www.youtube.com/embed/io-XphckiUQ',
            ]
        },
        {
            title:'family_planning_safe_sex',
            name:'Family planning and safe sex',
            links:[
                'https://www.youtube.com/embed/zHCP20AgDgE',
                'https://www.youtube.com/embed/OB2Z_iWzM3k',
                'https://www.youtube.com/embed/Om8wC6sZtpY',
                'https://www.youtube.com/embed/nu7IRJSjVL8',
                'https://www.youtube.com/embed/gaEGPNH33qk',
            ]
        },
        {
            title:'fertility',
            name:'Fertility',
            links:[
                'https://www.youtube.com/embed/c0OFx6GGoMU',
                'https://www.youtube.com/embed/xsB5_dyqfHA',
                'https://www.youtube.com/embed/isUlyGAChqE',
                'https://www.youtube.com/embed/m_woj2aQS04',
                'https://www.youtube.com/embed/Q2INFJMSjDw',
            ]
        },
        {
            title:'pcos',
            name:'Pcos',
            links:[
                'https://www.youtube.com/embed/MQ9yxiaglWs',
                'https://www.youtube.com/embed/Z7fHzQsel5k',
                'https://www.youtube.com/embed/Tx-plTiJn7A',
                'https://www.youtube.com/embed/p4VyT7qe26M',
                'https://www.youtube.com/embed/VnVrNV8zOPs',
            ]
        },
        {
            title:'health_wellness',
            name:'Health and wellness',
            links:[
                'https://www.youtube.com/embed/LZUGk6uM7KU',
                'https://www.youtube.com/embed/OsAbQ3PuJWA',
                'https://www.youtube.com/embed/Sz0qSvLMU9w',
                'https://www.youtube.com/embed/CyaOUszdoJY',
                'https://www.youtube.com/embed/q3m2Oi7AHfs',
            ]
        },{
            title:'adolescent_health',
            name:'',
            links:[
                'https://www.youtube.com/embed/yCdTHN2RJ4g',
                'https://www.youtube.com/embed/nCph6m2p4-k',
                'https://www.youtube.com/embed/NEXOCJXw6zs',
                'https://www.youtube.com/embed/ruRENiaspj4',
                'https://www.youtube.com/embed/0ODwkwvDq8M',
            ]
        }
    ]

    return (
        <div>


            <div className="main-area">
                <div className="container">
                    <div className="breadcrums py-1">
                        <div className="row pt-4">
                            <div className="col-auto col-md-10">
                                <nav aria-label="breadcrumb " className="second">
                                    <ol className="breadcrumb indigo lighten-6 first">
                                        <li className="breadcrumb-item font-weight-bold "><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">Home</span></a><i className="fa fa-angle-double-right " aria-hidden="true" /></li>
                                        <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a active-2" href="#"><span>Reviews & Videos</span></a></li>
                                    </ol>
                                </nav>
                            </div>

                            <h1 className='Title_header'>Our Treatment Videos</h1>
                        </div>
                    </div>
                </div>

                <div className='container px-0'>


                    <div className='mt-4'>

                        <div className='blogs_div'>
                            <div className='row'>

                                {videos.map((main)=>{
                                    return(
                                    main.title==location.pathname.substring(1) &&
                                    main.links.map((single)=>{
                                        return (
                                        <div className='col-md-6'>
                                            <div className="card">
                                                <div className="video1">

                                                    <ReactPlayer
                                                        url={single}
                                                        className="w-100 h-100"
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        )
                                    })
                                    )
                                })}

                            </div>
                        </div>
                    </div>



                    <div className='container'>

                        <div className='bookappointment my-4'>
                            <div class="row">
                                <div className="col-md-8">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30389.986713842034!2d83.33862783476562!3d17.80352080000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395b008312d2a5%3A0xa175a392dd875e9a!2sDr.%20Silpa&#39;s%20Clinic%20%40%20Vaatsalya%20hospital%20%2C%20best%20obstetrician%20and%20gynaecologist%20in%20Visakhapatnam!5e0!3m2!1sen!2sin!4v1682766403972!5m2!1sen!2sin"
                                        className="ifrmae2"
                                        style={{ border: "0" }}
                                        allowFullScreen
                                        loading="lazy"
                                    /> 
                                </div>

                                <div className="col-md-4">
                                    <div className='formSubmit'>
                                        <p>We Are Ready To Help You With A Smile!</p>
                                        <h4 className='font-weight-bold'><i class="fa fa-cell"></i>Call Us: <span className='colorora'>+91 7095925747</span></h4>

                                        <form>
                                            <input type="text" placeholder="Fullname*" className='form-control mb-3' />

                                            <input type="text" placeholder="Mobile*" className='form-control mb-3' />

                                            <input type="text" placeholder="Email*" className='form-control mb-3' />

                                            <select className='form-control mb-3'>
                                                <option>Pregnancy</option>
                                                <option>IVF</option>
                                                <option>Infertility</option>
                                            </select>
                                            <div className='my-3'>
                                                <textarea placeholder='Please tell us your concern in detail *' className='form-control my-3'>

                                                </textarea>
                                            </div>


                                        </form>

                                        <div className='my-3'>

                                            <a href="" className='bookapp my-4 w-100'>Book Appointment</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>
    )
}

