import React from 'react'
import Shieldpic from '../../assets/sheildpic.png';
import CallIcon from '@mui/icons-material/Call';
import Button from '@mui/material/Button';
import ReactPlayer from 'react-player';

function Aboutdr() {


  const videos=[
    'https://www.youtube.com/embed/Tx-plTiJn7A',
    'https://www.youtube.com/embed/p4VyT7qe26M',
    'https://www.youtube.com/embed/VnVrNV8zOPs',
    'https://www.youtube.com/embed/c0OFx6GGoMU',
    'https://www.youtube.com/embed/xsB5_dyqfHA',
    'https://www.youtube.com/embed/isUlyGAChqE',
    'https://www.youtube.com/embed/m_woj2aQS04',
    'https://www.youtube.com/embed/Q2INFJMSjDw',
    'https://www.youtube.com/embed/zHCP20AgDgE',
    'https://www.youtube.com/embed/OB2Z_iWzM3k',
    'https://www.youtube.com/embed/Om8wC6sZtpY',
    'https://www.youtube.com/embed/nu7IRJSjVL8',
  ]


  const reviews = [
    {
      'name': 'RAMANA VASAPALLI',
      'review': 'Dr.silpa madam received me very well when I consulted her.she speaks with a smile at every consultation.i followed up with madam for my second pregnancy journey.dr.silpahasa is the best gynaecologist in Visakhapatnam.'
    },
    {
      'name': 'Latha Bondada',
      'review': 'I came from palasa, srikakulam for my endometriosis cyst surgery.madam did laparoscopic cyst removal.i recovered very fast and went back to my hometown in 1day.i didn t expect this much fast recovery.very happy with dr silpahasa.any one can consult Dr.Silpahasa Samalla for laparoscopic surgeries.she is the best gynaecologist.'
    },
    {
      'name': 'Davuluri navya',
      'review': 'I m Navya, I went to Dr. Shilpa ma am with 103°F fever after 1 month of my baby delivery. She responded to me immediately and took care of me very well during my stay in the  hospital.'
    }
  ]

  return (
    <div>
      <div className='container-fluid p-0 m-0'>
        <div className='drbanner'>
          <div className='row'>
            <div className='drdetails'>
              <div className='container'>
                <div className="col-auto col-md-10 my-4">
                  <nav aria-label="breadcrumb" className="second">
                    <ol className="breadcrumb indigo lighten-6">
                      <li className="breadcrumb-item font-weight-bold "><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2 ">Home</span></a><i className="fa fa-angle-double-right text-white" aria-hidden="true" /></li>
                      <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">About me</span></a><i className="fa fa-angle-double-right text-uppercase text-white" aria-hidden="true" /></li>
                      <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a active-2 text-white" href="#"><span>About Dr.Silpahasa</span></a></li>
                    </ol>
                  </nav>
                </div>


                <div className='madamdetails pmsdy-4'>
                  <h4 class="nameDr blueone">Dr.Silpahasa Samalla</h4>
                  <p>MBBS,MS Obstetrics and Gynaecology</p>

                  <div className="ratings my-3">
                    <div className='starrate'>
                      <i className='fa fa-star starsize box-shadow'></i><span className="fontwave">5</span>
                    </div>
                    <div className='mr-4'>
                      <img src={Shieldpic} /><span> Verified & Most Trusted One</span>
                    </div>
                  </div>

                  <p className='w-60'>
                  Dr.Silpahasa Samalla is a well known Obstetrician and Gynaecologist in Visakhapatnam with over a decade of experience in field of medicine.She is one of the best infertility specialists and gynae laparoscopic surgeons in Andhra Pradesh.
                  </p>
                </div>


              </div>
            </div>
          </div>
        </div>



        <div className='container-fluid px-0'>
          <div className='border-bottom1 sticky-top'>
            <div className='container'>

              <div className='mainTabs'>
                <a href="#Aboutdr" className='b_anchor inactive'>About</a>
                <a href="#Reviews" className='b_anchor inactive'>Reviews</a>
                <a href="#Videos" className='b_anchor inactive'>Videos</a>
              </div>


              <div class="TabsContent">
                <div id="Aboutdr">
                  <div className="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
                    <div className="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
                      <div className="et_pb_text_inner"><h1><strong>About Dr.Silpahasa Samalla</strong></h1></div>
                    </div><div className="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
                      <div className="et_pb_text_inner">
                        <p>Dr. Silpahasa Samalla is a renowned obstetrician and gynaecologist in Visakhapatnam. She has extensive experience in obstetrics and gynaecology. She is trained in obstetrics and gynaecology from the prestigious Andhra Medical College and King George Hospital, Visakhapatnam. She did her training in gynaecological endoscopy at New Delhi and basic infertility from Ahmedabad.</p>
                        <p>Dr. Silpahasa Samalla is famous for her Gynae laparoscopic surgeries and hysteroscopic surgeries, and high success rate in infertility management with minimal intervention. Her patients love her for her communication skills, compassion, empathy, and respectful approach towards them, and appreciate her for her vast knowledge and exceptional skills.</p>
                        <p>She is recognized in the field of obstetrics and gynaecology and regarded as one of the best obstetricians and gynaecologists in Visakhapatnam.</p>
                      </div>
                    </div>
                  </div>


                  <div className='contactNow'>
                    <div className='contactTExt'>
                      <h5>Reach us by <br />
                        contacting our 24/7 support team!
                      </h5>
                    </div>


                    <div className="contact_section d-flex flex-direction-row justify-content-center align-items-center">
                      <div className="callsection">
                        <div className="phoneImg">
                          <CallIcon className="phonewidth" />
                        </div>
                        <div className="callus">
                          <p className="py-0 my-0 text-left1">Call Us 24/7</p>
                          <h5 className="py-0 my-0">+91-70959 25747</h5>
                        </div>
                      </div>

                      <div className="bookappointment">
                        <Button variant="contained" className="radiusBtn">Book Appointment</Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="Reviews">
                  <div id="Reviews" className='review_bg'>
                    <div className='mt-4 p-4'>

                      <div className='gview'>
                        <h3>Google Reviews</h3>
                      </div>

                      <div className='row'>

                      {reviews.map((review)=>{

                      return(
                      <div className='col-md-4'>
                        <div className='card min335 p-3'>
                          <div className='stars'>
                            <div className='start_cls'>
                              <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                              <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                              <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                              <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                              <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                            </div>

                            <div className='user'>
                              <h5>{review.name}</h5>
                            </div>
                          </div>

                          <div className='customerimg my-3'>

                            <p>{review.review}</p>
                          </div>
                        </div>


                      </div>
                      )
                      })}

                      </div>
                    </div>
                  </div>
                </div>

                


                <div id="Videos">
                  <div className='mt-4 p-4'>
                    <div className='gview'>
                      <h3>Our Treatment Videos</h3>
                    </div>
                    <div className='mt-4'>

                      <div className='blogs_div'>
                        <div className='row'>

                        {videos.map((videolink)=>{
                          return (
                            <div className='col-md-6 mt-4 mb-4'>
                              <div className="card">
                                <div className="video1">
                                  <ReactPlayer
                                    url={videolink}
                                    className="w-100 h-100"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        })}


                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aboutdr