import logo from "../assets/logos/logo.png";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import CallIcon from "@mui/icons-material/Call";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../assets/loader/loader.gif"


import Facebook from '../assets/facebook.png';
import Twitter from '../assets/twitter.png';
import Instagram from '../assets/instagram.png';
import LinkedIn from '../assets/linkedin.png';
import Youtube from '../assets/youtube.png';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CustomizedInputBase() {
  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Your Treatment"
        inputProps={{ "aria-label": "Search Your Treatment" }}
      />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
        <DirectionsIcon />
      </IconButton>

      <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
        <a href="https://www.youtube.com/@dr.silpahasavisakhapatnam6984/videos" target="_blank"><img src={Youtube}/></a>
        <a href="https://www.facebook.com/silpahasa.samalla" target="_blank"><img src={Facebook}/></a>
        <a href="https://www.instagram.com/drsilpahasasamalla/" target="_blank"><img src={Instagram}/></a>
        <a href="https://www.linkedin.com/in/dr-silpahasa-samalla-08a07871/" target="_blank"><img src={LinkedIn}/></a>
      </div>


    </Paper>
  );
}

function Header() {
  const [show, setShow] = useState(false);
  const [loadingBook, setLoadingBook] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    selected_date: "",
    selected_time: "",
    reason: "",
    message: "",
  });
  const [formData, setFormData] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingBook(true)
    axios
      .post("https://admin.drsilpahasa.com/api/v1/contact", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        handleClose(true);
        setLoadingBook(false)
      })
      .catch((err) => {
        setLoadingBook(false)
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };


  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};

  return (
    <>
      <ToastContainer />

      {/* <Router> */}
      <div className="top-header-section">
        <div className="container_view">
          <div className="headerSection">
            <div className="logo_Section">
              <Link to="/">
                {" "}
                <img src={logo} className="logosize" />
              </Link>
            </div>
            <div className="search_Section">
              <CustomizedInputBase className="widthnow240" />
            </div>

            <div className="contact_section">
              <div className="callsection">
                <div className="phoneImg">
                  <CallIcon className="phonewidth" />
                </div>
                <div className="callus">
                  <p className="py-0 my-0 text-left1">Call Us 24/7</p>
                  <a className="py-0 my-0 classnumber" href="tel:7095925747">
                    +91-70959 25747
                  </a>
                </div>
              </div>

              <div className="bookappointment">
              
                <Button
                  variant="contained"
                  className="radiusBtn"
                  onClick={handleShow}
                >
                  Book Appointment
                </Button>
                
              </div>

              <Modal show={show} onHide={handleClose} data-backdrop="false" >
                <Modal.Header closeButton>
                  <Modal.Title>Please Book Appointment </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="formSubmit">
                    <p>We Are Ready To Help You With A Smile!</p>

                    <form onSubmit={handleSubmit}>
                      <div className="col-md-12">
                        <input
                          type="text"
                          placeholder="Fullname*"
                          name="fullname"
                          id="fullname"
                          value={formData ? formData.fullname : ""}
                          onChange={handleChange}
                          className="form-control mb-3"
                        />
                      </div>

                      <div className="col-md-12">
                        <input
                          type="number"
                          placeholder="Mobile*"
                          name="mobile"
                          id="mobile"
                          value={formData ? formData.mobile : ""}
                          onChange={handleChange}
                          className="form-control mb-3"
                        />
                      </div>

                      <div className="col-md-12">
                        <input
                          type="email"
                          placeholder="Email*"
                          name="email"
                          id="email"
                          value={formData ? formData.email : ""}
                          onChange={handleChange}
                          className="form-control mb-3"
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                        <label id="c_date">Date</label>

                          <input
                          placeholder="Date"
                            type="date"
                            name="selected_date"
                            id="selected_date"
                            min={disablePastDate()}
                            value={formData ? formData.selected_date : ""}
                            onChange={handleChange}
                            className="form-control mb-3"
                          />
                        </div>

                        <div className="col-md-6">
                        <label id="c_time">Time</label>

                          <input
                            type="time"
                            placeholder="Time*"
                            name="selected_time"
                            id="selected_time"
                            value={formData ? formData.selected_time : ""}
                            onChange={handleChange}
                            className="form-control mb-3"
                          />
                        </div>
                      </div>
                      <select
                        className="form-control mb-3"
                        name="reason"
                        id="reason"
                        onChange={handleChange}
                        value={formData ? formData.reason : ""}
                      >
                        <option selected="{true}">Select Problem</option>
                        <option value="Pregnancy">Pregnancy</option>
                        <option value="Infertility">Infertility</option>
                        <option value="GynaecologicalProblems">
                          Gynaecological Problems
                        </option>
                        <option value="AdolescentHealth">
                          Adolescent Health
                        </option>
                        <option value="MenstrualProblems">
                          Menstrual Problems
                        </option>
                        <option value="GynaecologicalSurgeries">
                          Gynaecological Surgeries
                        </option>
                        <option value="LaparoscopyHysteroscopy">
                          Laparoscopy / Hysteroscopy
                        </option>
                      </select>
                      <div className="my-3">
                        <textarea
                          placeholder="Please tell us your concern in detail *"
                          name="message"
                          id="message"
                          value={formData ? formData.message : ""}
                          onChange={handleChange}
                          className="form-control my-3"
                        ></textarea>
                      </div>
                      <div className="my-3 gapivvu text-center">
                      {loadingBook?
                      
                        <img src={loader} style={{height:"100px"}}/>
                        :
                        <button type="submit" className="bookapp my-4 w-100">
                          Book Appointment
                        </button>
                        }
                      </div>
                    </form>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      {/* </Router> */}
    </>
  );
}

export default Header;
