import React, { useState } from "react";
import Madampic from "../../assets/madampic.png";
import Shieldpic from "../../assets/sheildpic.png";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/loader/loader.gif"


function Contact() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Aboutdr");
  };

  const [loadingBook, setLoadingBook] = useState(false);
  const [formData, setFormData] = useState({});

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    message: "",
    reason: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingBook(true);
    axios
      .post("https://admin.drsilpahasa.com/api/v1/contactpage", formData)
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        setLoadingBook(false);
      })
      .catch((err) => {
        setLoadingBook(false);
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <div>
      <div id="Drsilpas">
        <div className="container">
          <div className="mt-4 bgone">
            <h2 className="font-weight-bold px-4 py-2 text-dark">Contact us</h2>
            <div className="ddam-flex">
              <div className="flexdear">
                <div className="madampic">
                  <img
                    src={Madampic}
                    alt="dr.silpas"
                    className="madampicsize"
                  />
                </div>

                <div className="madamdetails pmsdy-4">
                  <h4 className="nameDr">Dr.Silpahasa Samalla</h4>
                  <p>MBBS,MS Obstetrics and Gynaecology</p>

                  <div className="ratings my-3">
                    <div className="starrate">
                      <i className="fa fa-star starsize"></i>
                      <span className="fontwave">5</span>
                    </div>
                    <div className="mr-4">
                      <img src={Shieldpic} />
                      <span className="font-weight-bold">
                        {" "}
                        Verified & Most Trusted One
                      </span>
                    </div>
                  </div>

                  <p>
                  
                  Dr.Silpahasa Samalla is a well known Obstetrician and Gynaecologist in Visakhapatnam with over a decade of experience in field of medicine.She is one of the best infertility specialists and gynae laparoscopic surgeons in Andhra Pradesh.
                  </p>

                  <div className="knowmore my-4">
                    <a href="Aboutdr" className="knowmore_cls" onClick={handleClick}>
                      Know more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bookappointment my-4">
            <div className="row">
              <div className="col-md-8">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30389.986713842034!2d83.33862783476562!3d17.80352080000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395b008312d2a5%3A0xa175a392dd875e9a!2sDr.%20Silpa&#39;s%20Clinic%20%40%20Vaatsalya%20hospital%20%2C%20best%20obstetrician%20and%20gynaecologist%20in%20Visakhapatnam!5e0!3m2!1sen!2sin!4v1682766403972!5m2!1sen!2sin"
                  className="ifrmae2"
                  style={{ border: "0" }}
                  allowFullScreen
                  loading="lazy"
                />
              </div>

              <div className="col-md-4">
                <div className="formSubmit">
                  <p>We Are Ready To Help You With A Smile!</p>
                  <h4 className="font-weight-bold">
                    <i className="fa fa-cell"></i>Call Us:{" "}
                    <span className="colorora">+91 7095925747</span>
                  </h4>

                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      placeholder="Fullname*"
                      name="fullname"
                      id="fullname"
                      value={formData ? formData.fullname : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <input
                      type="number"
                      placeholder="Mobile*"
                      name="mobile"
                      id="mobile"
                      value={formData ? formData.mobile : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <input
                      type="email"
                      placeholder="Email*"
                      name="email"
                      id="email"
                      value={formData ? formData.email : ""}
                      onChange={handleChange}
                      className="form-control mb-3"
                    />

                    <select
                      className="form-control mb-3"
                      name="reason"
                      id="reason"
                      onChange={handleChange}
                      value={formData ? formData.reason : ""}
                    >
                      <option>--Select Reason--</option>
                      <option>Pregnancy</option>
                      <option>IVF</option>
                      <option>Infertility</option>
                    </select>
                    <div className="my-3">
                      <textarea
                        placeholder="Please tell us your concern in detail *"
                        className="form-control my-3"
                        name="message"
                          id="message"
                          value={formData ? formData.message : ""}
                          onChange={handleChange}
                      ></textarea>
                    </div>

                  <div className="my-3 gapivvu text-center">
                      {loadingBook?
                      
                        <img src={loader} style={{height:"100px"}}/>
                        :
                        <button type="submit" className="bookapp my-4 w-100">
                          Book Appointment
                        </button>
                        }
                      </div>
                      </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
