import React from 'react'

export default function privacy_policy() {
  return (
    <div class="container mx-auto m-5">
        <h1 class="mt-5 mb-4">Privacy Policy</h1>
        <p>Welcome to Dr. Silpa's Clinic. This privacy policy explains how we collect, use, and protect your personal information.</p>

        <h2>Information Collection and Use</h2>
        <p>We may collect and use the following personal information:</p>
        <ul>
            <li>Your name, contact information, and medical history.</li>
            <li>Information related to your medical consultations and treatments.</li>
        </ul>

        <h2>Data Security</h2>
        <p>Your data is important to us, and we take measures to protect it:</p>
        <ul>
            <li>We use secure and encrypted connections to transmit and store your information.</li>
            <li>Access to your personal data is restricted to authorized personnel only.</li>
        </ul>

        <h2>Third-Party Services</h2>
        <p>We may use third-party services for analytics and communication. Their use of your data is governed by their privacy policies.</p>

        <h2>Changes to Privacy Policy</h2>
        <p>We may update this privacy policy, so please check it periodically for changes.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this privacy policy, please contact us at:</p>
        
    </div>

  )
}
