import React from 'react'
import Facebook from '../assets/facebook.png';
import Twitter from '../assets/twitter.png';
import Instagram from '../assets/instagram.png';
import LinkedIn from '../assets/linkedin.png';
import Youtube from '../assets/youtube.png';
import logo from "../assets/logos/logo.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Footer() {
  return (
    <div>
        {/* ======= Footer ======= */}
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 footer-contact">
          <Link to="/"> <img src={logo} width={100}/></Link>
            <p className='text-white'>
              Vaatsalya Hospital, MIG -223,<br/>
              Midhilapuri Vuda Colony,<br/> 
              Vizag, AP 530041 <br />
              
              <strong className='text-white'>Phone:</strong> +91 70959 25747<br />
              <strong className='text-white'>Email:</strong> dr.silpahaasa@gmail.com<br />
            </p>
          </div>
          <div className="col-lg-4 col-md-4 footer-links">
            <h4 className='text-white'>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right" /> <a href="#" className='text-white'>Home</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="page/gynaecology_problems_in_women" className='text-white'>Gynaecology</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="page/infertility_treatment" className='text-white'>IVF Intertility</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="page/post_menopausal_problems" className='text-white'>Paediatrics</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="terms_conditions" className='text-white'>Terms & Conditions</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="privacy_policy" className='text-white'>Privacy Policy</a></li>

              
            </ul>
          </div>
          <div className="col-lg-4 col-md-4 footer-links">
            <h4 className='text-white'>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right" /> <a href="Maternity_care" className='text-white'>Maternity Care</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="infertility" className='text-white'>Infertility</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="laparoscopy_hysteroscopy_surgeries" className='text-white'>Laparoscopic</a></li>
              <li><i className="bx bx-chevron-right" /> <a href="contact" className='text-white'>contact</a></li>
            </ul>
          </div>
          {/* <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4 className='text-white'>Join Our Newsletter</h4>
            <p className='text-white'>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action method="post">
              <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
            </form>
          </div> */}
        </div>
      </div>
    </div>
    <div className="container d-lg-flex py-4">
      <div className="me-lg-auto text-center text-lg-start">
        <div className="copyright">
          © Copyright <strong><span>Dr. Silpa's Clinic</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="#">SRM Software Solutions</a>
        </div>
      </div>
      <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
        <a href="https://www.youtube.com/@dr.silpahasavisakhapatnam6984/videos" target="_blank"><img src={Youtube}/></a>
        <a href="https://www.facebook.com/silpahasa.samalla" target="_blank"><img src={Facebook}/></a>
        <a href="https://www.instagram.com/drsilpahasasamalla/" target="_blank"><img src={Instagram}/></a>
        <a href="https://www.linkedin.com/in/dr-silpahasa-samalla-08a07871/" target="_blank"><img src={LinkedIn}/></a>
      </div>
    </div>
  </footer>{/* End Footer */}
    </div>
  )
}

export default Footer