import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useEffect } from "react";
import PCod from "../../assets/Gyanecology/PCOD.jpg";
import $ from "jquery";
import Gynaecology from "../../assets/Gyanecology/Gynaecology.jpg";
import Endometriosis from "../../assets/Gyanecology/Endometriosis.jpg";
import Menstrual from "../../assets/Gyanecology/Menstrual-Problems.jpg";
import Blog1 from "../../assets/Gyanecology/blog1.png";
import Blog2 from "../../assets/Gyanecology/blog2.png";
import Blog3 from "../../assets/Gyanecology/blog3.png";
import Madampic from "../../assets/madampic.png";
import Shieldpic from "../../assets/sheildpic.png";
import Cervicalcancer1 from "../../assets/all/Cervical-Cancer.jpg";
import axios from "axios";
import { useState } from "react";
import Parser from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from "../LoadingSpinner";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loader from "../../assets/loader/loader.gif"
import './dynamic.css'

function Dynamic_page() {


    const [isLoading, setIsLoading] = useState(false);
    const [page_data, setPage_data] = useState();


    // submit contact

    const [loadingBook, setLoadingBook] = useState(false);
    const [formData, setFormData] = useState({});

    const [emptyData, setEmptyData] = useState({
        fullname: "",
        email: "",
        mobile: "",
        message: "",
        reason: "",
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoadingBook(true);
        axios
            .post("https://admin.drsilpahasa.com/api/v1/contactpage", formData)
            .then((res) => {
                toast.success(res.data.message);
                setFormData(emptyData);
                setLoadingBook(false);
            })
            .catch((err) => {
                setLoadingBook(false);
                if (err.response.data.status === 500) {
                    toast.error("Server Error");
                }
                if (
                    err.response.data.status === 400 ||
                    err.response.data.status === 401
                ) {
                    if (typeof err.response.data.message === "object") {
                        for (var key in err.response.data.message) {
                            toast.error(err.response.data.message[key][0]);
                        }
                    } else {
                        toast.error(err.response.data.message);
                    }
                }
            });
    };
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };



    // end submit contact

    var url = document.URL
    const location = useLocation();
    var page_name = url.substr(url.lastIndexOf('/') + 1);
    useEffect(() => {
        setIsLoading(true)
        axios.get('https://admin.drsilpahasa.com/api/v1/dynamic_pages/' + page_name)
            .then(function (response) {
                setPage_data(response.data)
                setIsLoading(false)
            })


        $(document).ready(function () {
            $(".b_anchor").click(function () {
                $(".b_anchor").removeClass("b_active").addClass("inactive");
                $(this).removeClass("inactive").addClass("b_active");
            });

            $(".accordion-toggle").on("click", function (event) {
                event.preventDefault();
                // create accordion variables
                var accordion = $(this);
                var accordionContent = accordion.next(".accordion-content");

                // toggle accordion link open class
                accordion.toggleClass("open");
                // toggle accordion content
                accordionContent.slideToggle(250);
            });
        });



    }, [location]);





    if (page_data && !isLoading) {
        return (
            <div>
                <div className="main-area">
                    <div className="container">
                        <div className="breadcrums py-4">
                            <div className="row pt-4">
                                <div className="col-auto col-md-10">
                                    <nav aria-label="breadcrumb " className="second">
                                        <ol className="breadcrumb indigo lighten-6 first">
                                            <li className="breadcrumb-item font-weight-bold ">
                                                <a className="black-text text-uppercase bread_a" href="#">
                                                    <span className="mr-md-3 mr-2">Home</span>
                                                </a>
                                                <i
                                                    className="fa fa-angle-double-right "
                                                    aria-hidden="true"
                                                />
                                            </li>
                                            <li className="breadcrumb-item font-weight-bold">
                                                <a className="black-text text-uppercase bread_a" href="#">
                                                    <span className="mr-md-3 mr-2">{page_data.title}</span>
                                                </a>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                                <h1 className="Title_header">{page_data.title}</h1>
                            </div>
                        </div>
                    </div>

                    <div className="container px-0">
                        <div className="border-bottom1 sticky-top">
                            <div className="container">
                                <div className="mainTabs">
                                </div>

                                <div class="TabsContent">
                                    <div id="About">
                                        <div className="mt-4">
                                            <div className="diseas_about">
                                                <div class="row">
                                                    <div className="tabs_New">
                                                        {Parser(page_data.content)}
                                                        {page_data.images && !page_data.images=='none' &&
                                                            <img src={"/" + page_data.images} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div id="Drsilpas mb-5">
                                        <div className="mt-4 bgone">
                                            <div class="ddam-flex">
                                                <div className="row">
                                                    <div className="madampic col-4">
                                                        <img
                                                            src={Madampic}
                                                            alt="dr.Silpa"
                                                            className="madampicsize"
                                                        />
                                                    </div>

                                                    <div className="col-8">
                                                        <div className="madamdetails pmsdy-4 row">
                                                            <h4 class="nameDr">Dr.Silpahasa Samalla</h4>
                                                            <p>MBBS,MS Obstetrics and Gynaecology</p>

                                                            <div className="ratings my-3">
                                                                <div className="starrate">
                                                                    <i className="fa fa-star starsize"></i>
                                                                    <span className="fontwave">5</span>
                                                                </div>
                                                                <div className="mr-4">
                                                                    <img src={Shieldpic} />
                                                                    <span className="font-weight-bold">
                                                                        {" "}
                                                                        Verified & Most Trusted One
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <span className="font-weight-bold">
                                                                Dr.Silpahasa Samalla
                                                            </span>
                                                            is a well known Obstetrician and Gynaecologist in Visakhapatnam with over a decade of experience in field of medicine.She is one of the best infertility specialists and gynae laparoscopic surgeons in Andhra Pradesh.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bookappointment my-4 mt-4">
                                        <div class="row">
                                            <div className="col-md-8">
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30389.986713842034!2d83.33862783476562!3d17.80352080000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395b008312d2a5%3A0xa175a392dd875e9a!2sDr.%20Silpa&#39;s%20Clinic%20%40%20Vaatsalya%20hospital%20%2C%20best%20obstetrician%20and%20gynaecologist%20in%20Visakhapatnam!5e0!3m2!1sen!2sin!4v1682766403972!5m2!1sen!2sin"
                                                    className="ifrmae2"
                                                    style={{ border: "0" }}
                                                    allowFullScreen
                                                    loading="lazy"
                                                />
                                            </div>

                                            <div className="col-md-4">
                                                <div className="formSubmit">
                                                    <p>We Are Ready To Help You With A Smile!</p>
                                                    <h4 className="font-weight-bold">
                                                        <i className="fa fa-cell"></i>Call Us:{" "}
                                                        <span className="colorora">+91 7095925747</span>
                                                    </h4>

                                                    <form onSubmit={handleSubmit}>
                                                        <input
                                                            type="text"
                                                            placeholder="Fullname*"
                                                            name="fullname"
                                                            id="fullname"
                                                            value={formData ? formData.fullname : ""}
                                                            onChange={handleChange}
                                                            className="form-control mb-3"
                                                        />

                                                        <input
                                                            type="number"
                                                            placeholder="Mobile*"
                                                            name="mobile"
                                                            id="mobile"
                                                            value={formData ? formData.mobile : ""}
                                                            onChange={handleChange}
                                                            className="form-control mb-3"
                                                        />

                                                        <input
                                                            type="email"
                                                            placeholder="Email*"
                                                            name="email"
                                                            id="email"
                                                            value={formData ? formData.email : ""}
                                                            onChange={handleChange}
                                                            className="form-control mb-3"
                                                        />

                                                        <select
                                                            className="form-control mb-3"
                                                            name="reason"
                                                            id="reason"
                                                            onChange={handleChange}
                                                            value={formData ? formData.reason : ""}
                                                        >
                                                            <option>--Select Reason--</option>
                                                            <option>Pregnancy</option>
                                                            <option>IVF</option>
                                                            <option>Infertility</option>
                                                        </select>
                                                        <div className="my-3">
                                                            <textarea
                                                                placeholder="Please tell us your concern in detail *"
                                                                className="form-control my-3"
                                                                name="message"
                                                                id="message"
                                                                value={formData ? formData.message : ""}
                                                                onChange={handleChange}
                                                            ></textarea>
                                                        </div>

                                                        <div className="my-3 gapivvu text-center">
                                                            {loadingBook ?

                                                                <img src={loader} style={{ height: "100px" }} />
                                                                :
                                                                <button type="submit" className="bookapp my-4 w-100">
                                                                    Book Appointment
                                                                </button>
                                                            }
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <LoadingSpinner />
        )
    }
}
export default Dynamic_page;
