import React from "react";

export default function terms_conditions() {
  return (
    <div class="container mx-auto m-5">
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to Dr. Silpa's Clinic. These terms and conditions govern your
        use of our medical services. By accessing and using our services, you
        agree to comply with these terms. If you do not agree with any part of
        these terms, please do not use our services.
      </p>

      <h2>Appointment Scheduling</h2>
      <p>
        1. To schedule an appointment with any of our specialists, you must
        provide accurate and complete information.
      </p>
      <p>
        2. Cancellations or rescheduling must be made within a reasonable time
        frame to avoid any applicable fees.
      </p>

      <h2>Medical Services</h2>
      <p>
        3. Dr. Silpa's Clinic provides medical services in accordance with the
        highest professional standards.
      </p>
      <p>
        4. The information provided on our website and during consultations is
        for general informational purposes only and should not be considered as
        a substitute for professional medical advice.
      </p>

      <h2>Privacy</h2>
      <p>
        5. We respect your privacy and will handle your personal information in
        accordance with our Privacy Policy.
      </p>

      <h2>Payment and Fees</h2>
      <p>
        6. Payment for services is due at the time of your appointment, and we
        accept various payment methods as indicated.
      </p>
      <p>
        7. Fees are subject to change and will be communicated in advance
        whenever possible.
      </p>

      <h2>Disclaimer</h2>
      <p>
        8. While we strive to provide accurate and up-to-date information, Dr.
        Silpa's Clinic is not liable for any inaccuracies, errors, or omissions
        in the information provided.
      </p>

      <h2>Changes to Terms and Conditions</h2>
      <p>
        9. Dr. Silpa's Clinic reserves the right to update or modify these terms
        and conditions at any time. Please check this page regularly for any
        updates.
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have any questions or concerns regarding these terms and
        conditions, please contact us at:
      </p>
    </div>
  );
}
