import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import Img1 from "../assets/ivf_img.png";
import Img2 from "../assets/delivery_img.png";
import Img3 from "../assets/IUI.png";
import Img4 from "../assets/beds_img.png";
import M1 from "../assets/m1.jpg";
import M2 from "../assets/m2.jpg";
import M3 from "../assets/m3.jpg";
import M4 from "../assets/m4.jpg";
import Madampic from "../assets/madampic.png";
import M5 from "../assets/m5.jpg";
import M6 from "../assets/m6.jpg";
import Shieldpic from "../assets/sheildpic.png";
import M7 from "../assets/m7.jpg";
import M8 from "../assets/m8.jpg";
import M9 from "../assets/m9.jpg";
import Gyanec from "../assets/gynaecology.png";
import Surgery from "../assets/laparoscopicsurgery.png";
import Infertility from "../assets/infertlity.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import loader from "../assets/loader/loader.gif"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Name from "../assets/logos/name.png"
function Home() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/Aboutdr");
  };
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [emptyData, setEmptyData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    selected_date: "",
    selected_time: "",
    reason: "",
    message: "",
  });
  const [formData, setFormData] = useState({});

  

  const handleSubmit = (event) => {

    setLoading(true)

    event.preventDefault();

    axios
      .post(
        "https://admin.drsilpahasa.com/api/v1/contact",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setFormData(emptyData);
        handleClose(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.data.status === 500) {
          toast.error("Server Error");
        }
        if (
          err.response.data.status === 400 ||
          err.response.data.status === 401
        ) {
          if (typeof err.response.data.message === "object") {
            for (var key in err.response.data.message) {
              toast.error(err.response.data.message[key][0]);
            }
          } else {
            toast.error(err.response.data.message);
          }
        }
      });
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};


  return (
      <div>



<section
          id="hero"
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div className="container topspace mt-4" data-aos="fade-in">
            <h1
              className="welcomeCls"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              One Stop <span className="crls">Solution</span> for all Woman{" "}
              <br /> Health Needs. We Believe in Better <br /> Maternity Care
              for all.
            </h1>
            <h2
              className="text-dark small10"
              data-aos="fade-up"
              data-aos-delay={1000}
            >
              At Dr Silpa's Clinic we recognize the value of every woman, and we
              are guided by our commitment <br /> to treat you with respect,
              dignity and expertise, guided by, scientific and evidence based
              medicine <br /> and highest standard of health care.
            </h2>
            <div
              className="d-flex align-items-center"
              data-aos="fade-up"
              data-aos-delay={1000}
            >
              <i className="fa fa-arrow-right get-started-icon text-white" />
              <a
                href="#about"
                className="btn-get-started scrollto"
                onClick={handleShow}
              >
                Book Appointment
              </a>
            </div>

            <Modal show={show} onHide={handleClose} data-backdrop="false">
              <Modal.Header closeButton>
                <Modal.Title>Please Book Appointment </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="formSubmit">
                  <p>We Are Ready To Help You With A Smile!</p>

                  <form onSubmit={handleSubmit}>
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Fullname*"
                        name="fullname"
                        id="fullname"
                        value={formData ? formData.fullname : ""}
                        onChange={handleChange}
                        className="form-control mb-3"
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="number"
                        placeholder="Mobile*"
                        name="mobile"
                        id="mobile"
                        value={formData ? formData.mobile : ""}
                        onChange={handleChange}
                        className="form-control mb-3"
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="email"
                        placeholder="Email*"
                        name="email"
                        id="email"
                        value={formData ? formData.email : ""}
                        onChange={handleChange}
                        className="form-control mb-3"
                      />
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                      <label id="c_date">Date</label>

                        <input
                          type="date"
                          placeholder="Date*"
                          name="selected_date"
                          id="selected_date"
                          value={formData ? formData.selected_date : ""}
                          onChange={handleChange}
                          className="form-control mb-3"
                        />
                      </div>

                      <div className="col-md-6">
                      <label id="c_time">Time</label>

                        <input
                          type="time"
                          placeholder="Time*"
                          name="selected_time"
                          id="selected_time"
                          value={formData ? formData.selected_time : ""}
                          onChange={handleChange}
                          className="form-control mb-3"
                        />
                      </div>
                    </div>
                    <select
                      className="form-control mb-3"
                      name="reason"
                      id="reason"
                      onChange={handleChange}
                      value={formData ? formData.reason : ""}
                    >
                      <option selected>Select Problem</option>
                      <option value="Pregnancy">Pregnancy</option>
                      <option value="Infertility">Infertility</option>
                      <option value="GynaecologicalProblems">
                        Gynaecological Problems
                      </option>
                      <option value="AdolescentHealth">
                        Adolescent Health
                      </option>
                      <option value="MenstrualProblems">
                        Menstrual Problems
                      </option>
                      <option value="GynaecologicalSurgeries">
                        Gynaecological Surgeries
                      </option>
                      <option value="LaparoscopyHysteroscopy">
                        Laparoscopy / Hysteroscopy
                      </option>
                    </select>
                    <div className="my-3">
                      <textarea
                        placeholder="Please tell us your concern in detail"
                        name="message"
                        id="message"
                        value={formData ? formData.message : ""}
                        onChange={handleChange}
                        className="form-control my-3"
                      ></textarea>
                    </div>
                    <div className="my-3 gapivvu">
                      <button type="submit" className="bookapp my-4 w-100">
                        Book Appointment
                      </button>
                    </div>
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </section>


        
        {/* End Hero */}
        <main id="main">
          {/* ======= Why Us Section ======= */}
          <section id="why-us" className="why-us">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-5" data-aos="fade-up">
                  <div className="content">
                    <h3 className="text-white">
                      Why Choose Dr. Silpa's Clinic for your Healthy Family?
                    </h3>
                    <p>
                    Dr.Silpahasa Samalla is a well known obstetrician, gynaecologist,
                      laparoscopic surgeon and an infertility specialist. Dr.
                      Silpa’s Clinic is currently24 associated with vaatsalya
                      hospital, Midhilapuri VUDA Colony, Visakhapatnam, where
                      she is available 24/7. She is also available at Life care
                      Labs, Endada, Visakhapatnam, on prior appointment. She has
                      experience of over a decade in Obstetrics and Gynaecology
                      and we majorly focus on women’s health and their well
                      being. We believe that, "a healthy woman can build a
                      healthy family and a healthy society."
                    </p>
                    <div className="text-center">
                      <a href="#" className="more-btn">
                        Learn More <i className="bx bx-chevron-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-7 d-flex">
                  <div className="icon-boxes d-flex flex-column justify-content-center">
                    <div className="row">
                      <div
                        className="col-xl-3 d-flex align-items-stretch"
                        data-aos="fade-up"
                        data-aos-delay={100}
                      >
                        <div className="icon-box mt-4 mt-xl-0">
                          <i className="bx bx-receipt" />
                          <img src={Img1} width={90} />
                          <h4 className="yellowfb800d">1000 +</h4>
                          <p className="minht">High Risk Pregnancies</p>
                          <a href="#">
                            <i className="fa fa-arrow-right arrowright" />
                          </a>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 d-flex align-items-stretch"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <div className="icon-box mt-4 mt-xl-0">
                          <i className="bx bx-cube-alt" />
                          <img src={Img2} width={90} />
                          <h4 className="yellowfb800d">5000+</h4>
                          <p className="minht">Deliveries</p>
                          <a href="#">
                            <i className="fa fa-arrow-right arrowright" />
                          </a>
                        </div>
                      </div>
                      <div
                        className="col-xl-3 d-flex align-items-stretch"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="icon-box mt-4 mt-xl-0">
                          <i className="bx bx-images" />
                          <img src={Img3} width={90} />
                          <h4 className="yellowfb800d">1200+</h4>
                          <p className="minht">
                            Successful Infertility Treatments
                          </p>
                          <a href="#">
                            <i className="fa fa-arrow-right arrowright" />
                          </a>
                        </div>
                      </div>

                      <div
                        className="col-xl-3 d-flex align-items-stretch"
                        data-aos="fade-up"
                        data-aos-delay={300}
                      >
                        <div className="icon-box mt-4 mt-xl-0">
                          <i className="bx bx-images" />
                          <img src={Img4} width={90} />
                          <h4 className="yellowfb800d">3500+</h4>
                          <p className="minht">Gynaecological Surgeries</p>
                          <a href="#">
                            <i className="fa fa-arrow-right arrowright" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Why Us Section */}

          <section id="about" className="about section-bg">
            <div className="container">
              <div className="row text-center">
                <h1 className="font-weight-bold">
                  Centre for Excellent Care in High Risk{" "}
                  <span className="crls"> Pregnancies</span>, Infertility &
                  Laparoscopic Gynaecological Surgeries
                </h1>
                <p className="yrsexp">
                  10+ years of Experience as Consultant Obstetrician &
                  Gynaecologist
                </p>

                <div className="container my-4">
                  <div className="row">
                    <div
                      className="col-md-3 col-xs-12"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <img src={M1} width={200} className="w-100" />
                    </div>

                    <div
                      className="col-md-3 col-xs-12"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <img src={M2} width={200} className="w-100" />
                    </div>

                    <div
                      className="col-md-3 col-xs-12"
                      data-aos="fade-up"
                      data-aos-delay={500}
                    >
                      <img src={M5} width={200} className="w-100" />
                    </div>

                    <div
                      className="col-md-3 col-xs-12"
                      data-aos="fade-up"
                      data-aos-delay={1000}
                    >
                      <img src={M7} width={200} className="w-100" />
                    </div>
                  </div>
                </div>
              </div>
              <section id="about" className="about section-bg">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-xs-12 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                      <h3 data-aos="fade-up">About us</h3>
                      <h2 data-aos="fade-up">
                        Few Words About{" "}
                        <span className="blueclr">
                          Dr.Silpa's Clinic
                        </span>
                        in Visakhapatnam
                      </h2>
                      <p>
                        At Dr Silpa's Clinic we recognise the value of every
                        woman and are guided by our commitment to excellence in
                        healthcare. We demonstrate this by providing exemplary
                        physical, emotional and medical care for each of our
                        patients and their families. We offer an array of
                        medical and surgical services including 24-hour
                        emergency care backed by the latest technology.
                      </p>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="icon-box col-md-6" data-aos="fade-up">
                        <div className="icon">
                          <img src={Gyanec} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Obstetrics</a>
                        </h4>
                        <p className="description small">
                          These days every pregnancy is a precious pregnancy and
                          every baby is precious to us. We provide antenatal
                          care along with antenatal ultrasound scans including
                          TIFFA and NST along with High risk pregnancy care and
                          we are committed to deliver the baby to you by making
                          your pregnancy as comfortable as it can be, with our
                          services like painless deliveries, monitoring the
                          foetal health with CTG and Foetal Doppler and Foetal
                          growth scans and timely intervention for normal
                          delivery and also Caesarean section, if needed, we do
                          have good Paediatrician backup who is available 24/7
                          to cater the needs of your newborn.
                        </p>
                      </div>

                      <div className="icon-box col-md-6" data-aos="fade-up">
                        <div className="icon">
                          <img src={Gyanec} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Gynaecology</a>
                        </h4>

                        <p className="description small">
                          <strong>Dr. Silpahasa Samalla</strong> is experienced in
                          treating girls from adolescent age group to post
                          menopausal women in treating common gynaecological
                          problems like Menstrual problems in menarche,
                          menstrual issues, educating about menstrual hygiene,
                          PCOD, endometriosis, abnormal uterine bleeding,
                          medical and surgical management of fibroids, ovarian
                          cysts, breast and cervical cancer screening, early
                          screening with PAP smear and vaccination against
                          cervical cancer.
                        </p>
                      </div>
                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={100}
                      >
                        <div className="icon">
                          <img src={Surgery} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>General & Laparoscopic Surgery</a>
                        </h4>
                        <p className="description">
                        Dr.Silpahasa Samalla is one of the few qualified laparoscopic
                          gynaecologists in Visakhapatnam. She is trained at New
                          Delhi in dealing with Laparoscopic surgeries like
                          Ovarian cystectomy, Total Laparoscopic hysterectomy,
                          Endometriosis surgery, Laparoscopic tubal ligation,
                          Laparoscopic myomectomy (fibroid), Ovarian drilling
                          for PCOD with highest success rate and patients being
                          discharged on the same day of surgery. Hysteroscopic
                          procedures like diagnostic Hysteroscopy for
                          infertility, Hysteroscopic septal resection,
                          Hysteroscopic polypectomy, Hysteroscopic adhesiolysis
                          and Hysteroscopic myomectomy.
                        </p>
                      </div>
                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <div className="icon">
                          <img src={Infertility} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Infertility Assessment</a>
                        </h4>
                        <p className="description">
                          Infertility assessments include various examinations
                          as well as tests to find out the underlying reasons
                          behind infertility. You and your partner must take
                          infertility assessment tests to find out if there are
                          any serious infertility issues. In most cases,
                          infertility is easy to treat if you or your partner do
                          not have any serious conditions.
                        </p>
                      </div>

                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <div className="icon">
                          <img src={Infertility} width={70} />
                        </div>
                        <h4 className="title">
                          <a href>Family planning services</a>
                        </h4>
                        <p className="description">
                          Here couples are educated about the various birth
                          control methods available, the pros and cons of each
                          method. Temporary family planning methods like Birth
                          control pills, Copper -T, LNG IUS. Permanent methods
                          like laparoscopic tubal ligation with patient being
                          discharged within a few hours of surgery.
                        </p>
                      </div>

                      <div
                        className="icon-box col-md-6"
                        data-aos="fade-up"
                        data-aos-delay={200}
                      >
                        <h4 className="title">
                          <a href>See Our Treatment Videos</a>
                        </h4>

                        <div
                          className="video-box d-flex justify-content-center align-items-stretch"
                          data-aos="fade-right"
                        >
                          <a
                            href="https://www.youtube.com/@dr.silpahasavisakhapatnam6984"
                            target="_blank"
                            className="glightbox play-btn mb-4"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          {/* End About Section */}

          <div>
            {/* ======= Counts Section ======= */}
          
            {/* End Counts Section */}
            {/* ======= Services Section ======= */}

            <>
              {/* ======= Services Section ======= */}
              {/* <section id="services" className="services services">
                <div className="container" data-aos="fade-up">
                  <div className="section-title">
                    <h2>Services</h2>
                    <p>
                      We provide comprehensive woman’s healthcare right from
                      adolescence to menopause and beyond. This is your only
                      destination for all your health problems.
                    </p>
                    <p>
                      Millie is a new kind of maternity clinic, built by moms
                      and care providers who’ve been there. Our in-person clinic
                      brings you complete, continuous, and compassionate care
                      starting just 48 hours after a positive pregnancy test
                      through the first year of a child’s life.
                    </p>
                  </div>

                  <div className="row">
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="icon">
                        <i className="fas fa-heartbeat" />
                      </div>
                      <h4 className="title">
                        <a href="">Gynecology</a>
                      </h4>
                      <p className="description">
                        Gynaecology is a branch of medicine that specializes in
                        women’s reproductive health.
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      <div className="icon">
                        <i className="fas fa-pills" />
                      </div>
                      <h4 className="title">
                        <a href="">Urogynecology</a>
                      </h4>
                      <p className="description">
                        Urogynecology is a medical branch concerned with
                        urological problems affecting women.
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    >
                      <div className="icon">
                        <i className="fas fa-notes-medical" />
                      </div>
                      <h4 className="title">
                        <a href="">Gynecology Cancers</a>
                      </h4>
                      <p className="description">
                        Gynaecological cancers are Cancers that affect a woman’s
                        reproductive system.
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="icon">
                        <i className="fas fa-dna" />
                      </div>
                      <h4 className="title">
                        <a href="">Infertility</a>
                      </h4>
                      <p className="description">
                        Infertility problems occur when the couple is not able
                        to conceive naturally even after trying.
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    >
                      <div className="icon">
                        <i className="fas fa-wheelchair" />
                      </div>
                      <h4 className="title">
                        <a href="">Laparoscopic Surgery</a>
                      </h4>
                      <p className="description">
                        Laparoscopic surgery involves examining the abdominal
                        organs or a woman’s reproductive system.
                      </p>
                    </div>
                    <div
                      className="col-lg-4 col-md-6 icon-box"
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    >
                      <div className="icon">
                        <i className="fas fa-notes-medical" />
                      </div>
                      <h4 className="title">
                        <a href="">Maternity</a>
                      </h4>
                      <p className="description">
                        Every mother and baby deserves the best care possible
                        and it is our priority to cater to their needs.
                      </p>
                    </div>
                  </div>
                </div>
              </section> */}
              {/* End Services Section */}
            </>

            {/* ======= Appointment Section ======= */}
            <section id="appointment" className="appointment section-bg">
              <div className="container">
                <div className="section-title">
                  <h2>Make an Appointment</h2>
                </div>
                <ToastContainer />
                <form onSubmit={handleSubmit} className="php-email-form">
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <input
                        type="text"
                        name="fullname"
                        className="form-control"
                        id="fullname"
                        value={formData ? formData.fullname : ""}
                        onChange={handleChange}
                        placeholder="Your Name"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate" />
                    </div>
                    <div className="col-md-4 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        value={formData ? formData.email : ""}
                        onChange={handleChange}
                        placeholder="Your Email"
                        data-rule="email"
                        data-msg="Please enter a valid email"
                      />
                      <div className="validate" />
                    </div>
                    <div className="col-md-4 form-group mt-3 mt-md-0">
                      <input
                        type="number"
                        className="form-control"
                        name="mobile"
                        id="mobile"
                        value={formData ? formData.mobile : ""}
                        onChange={handleChange}
                        placeholder="Your Phone"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 form-group mt-3">
                    <label id="c_date">Date</label>
                      <input
                        type="date"
                        name="date"
                        min={disablePastDate()}
                        className="form-control datepicker"
                        id="selected_date"
                        value={formData ? formData.selected_date : ""}
                        onChange={handleChange}
                        placeholder="Appointment Date"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate" />
                    </div>

                    <div className="col-md-4 form-group mt-3">
                      <label id="c_time">Time</label>
                      <input
                        type="time"
                        name="time"
                        className="form-control datepicker"
                        id="selected_time"
                        value={formData ? formData.selected_time : ""}
                        onChange={handleChange}
                        placeholder="Appointment time"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate" />
                    </div>

                    <div className="col-md-4 form-group mt-3">
                      <select
                        name="department"
                        id="reason"
                        onChange={handleChange}
                        value={formData ? formData.reason : ""}
                        className="form-select"
                      >
                        <option selected>Select Problem</option>
                        <option value="Pregnancy">Pregnancy</option>
                        <option value="Infertility">Infertility</option>
                        <option value="GynaecologicalProblems">
                          Gynaecological Problems
                        </option>
                        <option value="AdolescentHealth">
                          Adolescent Health
                        </option>
                        <option value="MenstrualProblems">
                          Menstrual Problems
                        </option>
                        <option value="GynaecologicalSurgeries">
                          Gynaecological Surgeries
                        </option>
                        <option value="LaparoscopyHysteroscopy">
                          Laparoscopy / Hysteroscopy
                        </option>
                      </select>
                      <div className="validate" />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      id="message"
                      value={formData ? formData.message : ""}
                      onChange={handleChange}
                      placeholder="Message (Optional)"
                      defaultValue={""}
                    />
                    <div className="validate" />
                  </div>
                  <div className="mb-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">
                      Your appointment request has been sent successfully. Thank
                      you!
                    </div>
                  </div>
                  <div className="text-center">
                  {loading?
                    <img src={loader} style={{height:"100px"}}/>
                    :
                    <button type="submit">Make an Appointment</button>
                  }
                  </div>
                </form>
              </div>
            </section>
            {/* End Appointment Section */}
            {/* ======= Departments Section ======= */}
            {/* <section id="departments" className="departments">
              <div className="container">
                <div className="section-title">
                  <h2>Departments</h2>
                  <p>
                    Magnam dolores commodi suscipit. Necessitatibus eius
                    consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                    velit. Quisquam quos quisquam cupiditate. Et nemo qui
                    impedit suscipit alias ea. Quia fugiat sit in iste officiis
                    commodi quidem hic quas.
                  </p>
                </div>
                <div className="row gy-4">
                  <div className="col-lg-3">
                    <ul className="nav nav-tabs flex-column">
                    <li className="nav-item">
                        <a
                          data-bs-toggle="tab"
                          href="#tab-1"
                          className="nav-link active show"

                        >
                          <i className="fas fa-heartbeat" /> Obstetrics and
                          Gynaecology
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-2"
                        >
                          <i className="fas fa-heart" /> Paediatrics
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-3"
                        >
                          <i className="fas fa-plus-square" /> General Medicine
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-4"
                        >
                          <i className="fas fa-heartbeat" /> General Surgery
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-5"
                        >
                          <i className="fas fa-stethoscope" /> Gastro
                          Enterology 
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#tab-6"
                        >
                          <i className="fas fa-stethoscope" /> Urology 
                        </a>
                      </li>
                     
                      

                      
                    </ul>
                  </div>
                  <div className="col-lg-9">
                    <div className="tab-content">
                      
                    <div className="tab-pane active show" id="tab-1">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Obstetrics & Gynaecology Cardiology</h3>
                            <p className="fst-italic">
                              Qui laudantium consequatur laborum sit qui ad
                              sapiente dila parde sonata raqer a videna mareta
                              paulona marka
                            </p>
                            <p>
                              Et nobis maiores eius. Voluptatibus ut enim
                              blanditiis atque harum sint. Laborum eos ipsum
                              ipsa odit magni. Incidunt hic ut molestiae aut
                              qui. Est repellat minima eveniet eius et quis
                              magni nihil. Consequatur dolorem quaerat quos qui
                              similique accusamus nostrum rem vero
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Dept1} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-2">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Paediatrics </h3>
                            <p className="fst-italic">
                              Qui laudantium consequatur laborum sit qui ad
                              sapiente dila parde sonata raqer a videna mareta
                              paulona marka
                            </p>
                            <p>
                              Ea ipsum voluptatem consequatur quis est. Illum
                              error ullam omnis quia et reiciendis sunt sunt
                              est. Non aliquid repellendus itaque accusamus eius
                              et velit ipsa voluptates. Optio nesciunt eaque
                              beatae accusamus lerode pakto madirna desera vafle
                              de nideran pal
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Dept2} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-3">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>General Medicine</h3>
                            <p className="fst-italic">
                              Eos voluptatibus quo. Odio similique illum id
                              quidem non enim fuga. Qui natus non sunt dicta
                              dolor et. In asperiores velit quaerat perferendis
                              aut
                            </p>
                            <p>
                              Iure officiis odit rerum. Harum sequi eum illum
                              corrupti culpa veritatis quisquam. Neque
                              necessitatibus illo rerum eum ut. Commodi ipsam
                              minima molestiae sed laboriosam a iste odio. Earum
                              odit nesciunt fugiat sit ullam. Soluta et harum
                              voluptatem optio quae
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Dept3} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-4">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>General surgery</h3>
                            <p className="fst-italic">
                              Totam aperiam accusamus. Repellat consequuntur
                              iure voluptas iure porro quis delectus
                            </p>
                            <p>
                              Eaque consequuntur consequuntur libero expedita in
                              voluptas. Nostrum ipsam necessitatibus aliquam
                              fugiat debitis quis velit. Eum ex maxime error in
                              consequatur corporis atque. Eligendi asperiores
                              sed qui veritatis aperiam quia a laborum inventore
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Dept4} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="tab-5">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Gastro Enterology </h3>
                            <p className="fst-italic">
                              Omnis blanditiis saepe eos autem qui sunt debitis
                              porro quia.
                            </p>
                            <p>
                              Exercitationem nostrum omnis. Ut reiciendis
                              repudiandae minus. Omnis recusandae ut non quam ut
                              quod eius qui. Ipsum quia odit vero atque qui
                              quibusdam amet. Occaecati sed est sint aut vitae
                              molestiae voluptate vel
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Dept5} className="img-fluid" />
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="tab-6">
                        <div className="row gy-4">
                          <div className="col-lg-8 details order-2 order-lg-1">
                            <h3>Urology</h3>
                            <p className="fst-italic">
                              Omnis blanditiis saepe eos autem qui sunt debitis
                              porro quia.
                            </p>
                            <p>
                              Exercitationem nostrum omnis. Ut reiciendis
                              repudiandae minus. Omnis recusandae ut non quam ut
                              quod eius qui. Ipsum quia odit vero atque qui
                              quibusdam amet. Occaecati sed est sint aut vitae
                              molestiae voluptate vel
                            </p>
                          </div>
                          <div className="col-lg-4 text-center order-1 order-lg-2">
                            <img src={Dept5} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* End Departments Section */}
            {/* ======= Doctors Section ======= */}
            <section id="doctors" className="doctors">
              <div className="container">
                <div className="section-title">
                  <h2>OUR DOCTOR</h2>
                  <p>Know the Expert Before You Visit Our Clinic</p>
                </div>
                <div className="row">
                  <div className="mt-4 bgone">
                    <div class="ddam-flex">
                      <div className="flexdear">
                        <div className="madampic">
                          <img
                            src={Madampic}
                            alt="dr.silpas"
                            className="madampicsize"
                          />
                        </div>

                        <div className="madamdetails pmsdy-4">
                          <div className="about_silpa">
                          <img src={Name} className="img-fluid" style={{ height: '50px' }} />
                            <p>MBBS,MS Obstetrics and Gynaecology</p>

                            <div className="ratings my-3">
                              <div className="starrate">
                                <i className="fa fa-star starsize"></i>
                                <span className="fontwave">5</span>
                              </div>
                              <div className="mr-4">
                                <img src={Shieldpic} />
                                <span className="font-weight-bold">
                                  {" "}
                                  Verified & Most Trusted One
                                </span>
                              </div>
                            </div>
                          </div>
                          <p>
                            <span className="font-weight-bold">
                            Dr Silpahasa Samalla
                            </span>
                             &nbsp; is a well known Obstetrician and Gynaecologist in Visakhapatnam with over a decade of experience in field of medicine.She is one of the best infertility specialists and gynae laparoscopic surgeons in Andhra Pradesh.
                          </p>

                          <div className="knowmore my-4">
                            <a
                              href=""
                              className="knowmore_cls"
                              onClick={handleClick}
                            >
                              Know more
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Doctors Section */}
            {/* ======= Frequently Asked Questions Section ======= */}
            {/* <section id="faq" className="faq section-bg">
              <div className="container">
                <div className="section-title">
                  <h2>Frequently Asked Questions</h2>
                  <p>
                    Magnam dolores commodi suscipit. Necessitatibus eius
                    consequatur ex aliquid fuga eum quidem. Sit sint consectetur
                    velit. Quisquam quos quisquam cupiditate. Et nemo qui
                    impedit suscipit alias ea. Quia fugiat sit in iste officiis
                    commodi quidem hic quas.
                  </p>
                </div>
                <div className="faq-list">
                  <ul>
                    <li data-aos="fade-up">
                      <i className="bx bx-help-circle icon-help" />{" "}
                      <a
                        data-bs-toggle="collapse"
                        className="collapse"
                        data-bs-target="#faq-list-1"
                      >
                        Non consectetur a erat nam at lectus urna duis?{" "}
                        <i className="bx bx-chevron-down icon-show" />
                        <i className="bx bx-chevron-up icon-close" />
                      </a>
                      <div
                        id="faq-list-1"
                        className="collapse show"
                        data-bs-parent=".faq-list"
                      >
                        <p>
                          Feugiat pretium nibh ipsum consequat. Tempus iaculis
                          urna id volutpat lacus laoreet non curabitur gravida.
                          Venenatis lectus magna fringilla urna porttitor
                          rhoncus dolor purus non.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-delay={100}>
                      <i className="bx bx-help-circle icon-help" />{" "}
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-list-2"
                        className="collapsed"
                      >
                        Feugiat scelerisque varius morbi enim nunc?{" "}
                        <i className="bx bx-chevron-down icon-show" />
                        <i className="bx bx-chevron-up icon-close" />
                      </a>
                      <div
                        id="faq-list-2"
                        className="collapse"
                        data-bs-parent=".faq-list"
                      >
                        <p>
                          Dolor sit amet consectetur adipiscing elit
                          pellentesque habitant morbi. Id interdum velit laoreet
                          id donec ultrices. Fringilla phasellus faucibus
                          scelerisque eleifend donec pretium. Est pellentesque
                          elit ullamcorper dignissim. Mauris ultrices eros in
                          cursus turpis massa tincidunt dui.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-delay={200}>
                      <i className="bx bx-help-circle icon-help" />{" "}
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-list-3"
                        className="collapsed"
                      >
                        Dolor sit amet consectetur adipiscing elit?{" "}
                        <i className="bx bx-chevron-down icon-show" />
                        <i className="bx bx-chevron-up icon-close" />
                      </a>
                      <div
                        id="faq-list-3"
                        className="collapse"
                        data-bs-parent=".faq-list"
                      >
                        <p>
                          Eleifend mi in nulla posuere sollicitudin aliquam
                          ultrices sagittis orci. Faucibus pulvinar elementum
                          integer enim. Sem nulla pharetra diam sit amet nisl
                          suscipit. Rutrum tellus pellentesque eu tincidunt.
                          Lectus urna duis convallis convallis tellus. Urna
                          molestie at elementum eu facilisis sed odio morbi quis
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-delay={300}>
                      <i className="bx bx-help-circle icon-help" />{" "}
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-list-4"
                        className="collapsed"
                      >
                        Tempus quam pellentesque nec nam aliquam sem et tortor
                        consequat?{" "}
                        <i className="bx bx-chevron-down icon-show" />
                        <i className="bx bx-chevron-up icon-close" />
                      </a>
                      <div
                        id="faq-list-4"
                        className="collapse"
                        data-bs-parent=".faq-list"
                      >
                        <p>
                          Molestie a iaculis at erat pellentesque adipiscing
                          commodo. Dignissim suspendisse in est ante in. Nunc
                          vel risus commodo viverra maecenas accumsan. Sit amet
                          nisl suscipit adipiscing bibendum est. Purus gravida
                          quis blandit turpis cursus in.
                        </p>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-delay={400}>
                      <i className="bx bx-help-circle icon-help" />{" "}
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-list-5"
                        className="collapsed"
                      >
                        Tortor vitae purus faucibus ornare. Varius vel pharetra
                        vel turpis nunc eget lorem dolor?{" "}
                        <i className="bx bx-chevron-down icon-show" />
                        <i className="bx bx-chevron-up icon-close" />
                      </a>
                      <div
                        id="faq-list-5"
                        className="collapse"
                        data-bs-parent=".faq-list"
                      >
                        <p>
                          Laoreet sit amet cursus sit amet dictum sit amet
                          justo. Mauris vitae ultricies leo integer malesuada
                          nunc vel. Tincidunt eget nullam non nisi est sit amet.
                          Turpis nunc eget lorem dolor sed. Ut venenatis tellus
                          in metus vulputate eu scelerisque.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </section> */}
            {/* End Frequently Asked Questions Section */}
            {/* ======= Testimonials Section ======= */}

            {/* ======= Gallery Section ======= */}
            <section id="gallery" className="gallery">
              <div className="container">
                <div className="section-title">
                  <h2>Gallery</h2>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row g-0">
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href={M1} className="galelry-lightbox">
                          <img src={M1} className="img-fluid rounded" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href={M2} className="galelry-lightbox">
                          <img src={M2} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href={M3} className="galelry-lightbox">
                          <img src={M3} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href={M4} className="galelry-lightbox">
                          <img src={M4} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href={M5} className="galelry-lightbox">
                          <img src={M5} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href={M6} className="galelry-lightbox">
                          <img src={M6} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href={M7} className="galelry-lightbox">
                          <img src={M7} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="gallery-item">
                      <div className="card-body">
                        <a href={M8} className="galelry-lightbox">
                          <img src={M8} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Gallery Section */}
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact">
              <div className="container">
                <div className="section-title">
                  <h2>Contact</h2>
                </div>
              </div>
              <div></div>
              <div className="container">
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <div className="info">
                      <div className="address">
                        <i className="fa fa-map-marker" />
                        <h4>Location:</h4>
                        <p>
                          Vaatsalya Hospital, MIG -223,
                          <br />
                          Midhilapuri Vuda Colony, Vizag, AP 530041
                        </p>
                      </div>
                      <div className="email">
                        <i className="fa fa-envelope" />
                        <h4>Email:</h4>
                        <p>dr.silpahaasa@gmail.com</p>
                      </div>
                      <div className="phone">
                        <i className="fa fa-phone" />
                        <h4>Call:</h4>
                        <p>+91 7095925747</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 mt-5 mt-lg-0">
                    <iframe
                      style={{ border: 0, width: "100%", height: 350 }}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30389.986713842034!2d83.33862783476562!3d17.80352080000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395b008312d2a5%3A0xa175a392dd875e9a!2sDr.%20Silpa&#39;s%20Clinic%20%40%20Vaatsalya%20hospital%20%2C%20best%20obstetrician%20and%20gynaecologist%20in%20Visakhapatnam!5e0!3m2!1sen!2sin!4v1682766403972!5m2!1sen!2sin"
                      allowFullScreen
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* End Contact Section */}
          </div>
        </main>
        {/* End #main */}
      </div>
  );
}

export default Home;
