import React from 'react'
import Shieldpic from '../../assets/sheildpic.png';
import CallIcon from '@mui/icons-material/Call';
import Button from '@mui/material/Button';
import Blog1 from '../../assets/Gyanecology/blog1.png';
import Blog2 from '../../assets/Gyanecology/blog2.png';
import Blog3 from '../../assets/Gyanecology/blog3.png';
import Gynaecology from '../../assets/Gyanecology/Gynaecology.jpg';
import H1 from '../../assets/hosp1.jpg';
import H2 from '../../assets/hosp2.jpg';
import H3 from '../../assets/hosp3.jpg';
import H4 from '../../assets/hosp4.jpg';
import H5 from '../../assets/hosp5.jpg';
import Madampic from '../../assets/madampic.png';
import M1 from '../../assets/m1.jpg';
import M2 from '../../assets/m2.jpg';
import M3 from '../../assets/m3.jpg';
import M4 from '../../assets/m4.jpg';
import M5 from '../../assets/m5.jpg';
import M6 from '../../assets/m6.jpg';
import M7 from '../../assets/m7.jpg';
import M8 from '../../assets/m8.jpg';

function Aboutclinic() {

  const videos=[
    'https://www.youtube.com/embed/Tx-plTiJn7A',
    'https://www.youtube.com/embed/p4VyT7qe26M',
    'https://www.youtube.com/embed/VnVrNV8zOPs',
    'https://www.youtube.com/embed/c0OFx6GGoMU',
    'https://www.youtube.com/embed/xsB5_dyqfHA',
    'https://www.youtube.com/embed/isUlyGAChqE',
    'https://www.youtube.com/embed/m_woj2aQS04',
    'https://www.youtube.com/embed/Q2INFJMSjDw',
    'https://www.youtube.com/embed/zHCP20AgDgE',
    'https://www.youtube.com/embed/OB2Z_iWzM3k',
    'https://www.youtube.com/embed/Om8wC6sZtpY',
    'https://www.youtube.com/embed/nu7IRJSjVL8',
  ]
  

  const reviews = [
    {
      'name': 'Govindarajulu Sammala',
      'review': 'Words are less to thank Dr. Shilpa. My friend wife visited her for pregnancy. It was a normal delivery. She took care of all the detailings required for a smooth and safe delivery. She understands the mindset of the patient and clarifies all their doubts..thanks a lot'
    },
    {
      'name': 'Manohar gunna',
      'review': 'We met dr.silpahasa madam for my wife s period issues and pregnancy concern.after we spoke to her.we felt very relaxed..the way she talks to us with a smile it self cures half the illness.highly recommend dr.silpahasa for all gynaecology issues.'
    },
    {
      'name': 'Davuluri navya',
      'review': 'I m Navya, I went to Dr. Shilpa ma am with 103°F fever after 1 month of my baby delivery. She responded to me immediately and took care of me very well during my stay in the  hospital.'
    }
  ]


  return (
    <div>
      <div>
        <div className='container-fluid p-0 m-0'>
          <div className='hspbanner'>
            <div className='row'>
              <div className='drdetails'>
                <div className='container'>
                  <div className="col-auto col-md-10 my-4">
                    <nav aria-label="breadcrumb" className="second">
                      <ol className="breadcrumb indigo lighten-6">
                        <li className="breadcrumb-item font-weight-bold "><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2 ">Home</span></a><i className="fa fa-angle-double-right text-white" aria-hidden="true" /></li>
                        <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">About me</span></a><i className="fa fa-angle-double-right text-uppercase text-white" aria-hidden="true" /></li>
                        <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a active-2 text-white" href="#"><span>About Dr.Silpa's</span></a></li>
                      </ol>
                    </nav>
                  </div>


                  <div className='madamdetails pmsdy-4'>
                    <h4 class="nameDr blueone">Dr.Silpa's  Clinic</h4>
                    <p>9 Years Experience Overall</p>

                    <div className="ratings my-3">
                      <div className='starrate'>
                        <i className='fa fa-star starsize box-shadow'></i><span className="fontwave">5</span>
                      </div>
                      <div className='mr-4'>
                        <img src={Shieldpic} /><span> Verified & Most Trusted One</span>
                      </div>
                    </div>

                    <p className='w-80'>Dr.Silpahasa Samalla is a well known Obstetrician and Gynaecologist in Visakhapatnam with over a decade of experience in field of medicine.She is one of the best infertility specialists and gynae laparoscopic surgeons in Andhra Pradesh.</p>

                  </div>


                </div>
              </div>
            </div>
          </div>



          <div className='container-fluid px-0'>
            <div className='border-bottom1 sticky-top'>
              <div className='container'>

                <div className='mainTabs'>
                  <a href="#Aboutdr" className='b_anchor inactive'>About</a>
                  <a href="#Photos" className='b_anchor inactive'>Photos</a>
                  <a href="#Reviews" className='b_anchor inactive'>Reviews</a>
                  <a href="#Doctor" className='b_anchor inactive'>Doctor</a>
                </div>


                <div class="TabsContent">
                  <div id="Aboutdr">
                    <div className="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">
                      <div className="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
                        <div className="et_pb_text_inner"><h1><strong>About Dr.Silpa's Clinic</strong></h1></div>
                      </div><div className="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
                        <div className="et_pb_text_inner">
                          <p>Dr.Silpahasa Samalla is currently practicing at Vaatsalya Hospital, Visakhapatnam. She is working in a multi-specialty hospital with a team of specialist and super specialist doctors to provide the highest quality of care for women. </p>
                          <p>The hospital has been successful in putting smiles on the faces of women and children, and has given the joy of motherhood and fulfilled the dream of parenthood for many couples. The specialist doctors available at the hospital include general physicians, general surgeons, pediatricians, neonatologists, anesthesiologists and critical care specialists, fetal medicine specialists, radiologists, orthopedicians, cardiologists, and endocrinologists.</p>
                          <p>The hospital has facilities like 24*7 emergency services, excellent nursing care, round the clock resident doctors, pharmacy, laboratory, X-ray, and ultrasound services.</p>
                        </div>
                      </div>
                    </div>


                    <div className='contactNow'>
                      <div className='contactTExt'>
                        <h5>Reach us by <br />
                          contacting our 24/7 support team!
                        </h5>
                      </div>


                      <div className="contact_section d-flex flex-direction-row justify-content-center align-items-center">
                        <div className="callsection">
                          <div className="phoneImg">
                            <CallIcon className="phonewidth" />
                          </div>
                          <div className="callus">
                            <p className="py-0 my-0 text-left1">Call Us 24/7</p>
                            <h5 className="py-0 my-0">+91-70959 25747</h5>
                          </div>
                        </div>

                        <div className="bookappointment">
                          <Button variant="contained" className="radiusBtn">Book Appointment</Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="Reviews">
                    <div className='review_bg'>
                      <div className='mt-4 p-4'>

                        <div className='gview'>
                          <h3>Google Reviews</h3>
                        </div>

                        <div className='row'>

                        {reviews.map((review)=>{
                          return (
                        
                          <div className='col-md-4'>
                            <div className='card min335 p-3'>
                              <div className='stars'>
                                <div className='start_cls'>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                </div>

                                <div className='user'>
                                  <h5>{review.name}</h5>
                                </div>
                              </div>

                              <div className='customerimg my-3'>

                                <p>{review.review}</p>
                              </div>
                            </div>


                          </div>
                          )
                          })}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="Photos">
                    <div>
                      <div className='mt-4 p-4'>
                        <div className='gview'>
                          <h3>Clinic Infrastructure Images</h3>
                        </div>
                        <div className="container-fluid">
                          <div className="row g-0">
                            <div className="col-lg-3 col-md-4">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M1} className="galelry-lightbox">
                                    <img src={M1} className="img-fluid rounded" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M2} className="galelry-lightbox">
                                    <img src={M2} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M3} className="galelry-lightbox">
                                    <img src={M3} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M4} className="galelry-lightbox">
                                    <img src={M4} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M5} className="galelry-lightbox">
                                    <img src={M5} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M6} className="galelry-lightbox">
                                    <img src={M6} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M7} className="galelry-lightbox">
                                    <img src={M7} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="gallery-item">
                                <div className="card-body">
                                  <a href={M8} className="galelry-lightbox">
                                    <img src={M8} className="img-fluid" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="Doctor">



                    <div className='mt-4 bgone'>
                      <h2 className='font-weight-bold px-4 py-2 text-dark'>About Dr.Silpahasa Samalla</h2>
                      <div class="ddam-flex">
                        <div className='flexdear'>
                          <div className='madampic'>
                            <img src={Madampic} alt="dr.silpas" className='madampicsize' />
                          </div>

                          <div className='madamdetails pmsdy-4'>
                            <h4 class="nameDr">Dr.Silpahasa Samalla</h4>
                            <p>MBBS,MS Obstetrics and Gynaecology</p>

                            <div className="ratings my-3">
                              <div className='starrate'>
                                <i className='fa fa-star starsize'></i><span className="fontwave">5</span>
                              </div>
                              <div className='mr-4'>
                                <img src={Shieldpic} /><span className='font-weight-bold'> Verified & Most Trusted One</span>
                              </div>
                            </div>

                            <p><span className='font-weight-bold'>Dr.Silpahasa Samalla</span>&nbsp; is a well known Obstetrician and Gynaecologist in Visakhapatnam with over a decade of experience in field of medicine.She is one of the best infertility specialists and gynae laparoscopic surgeons in Andhra Pradesh.</p>

                            <div className='knowmore my-4'>
                              <a href="Aboutdr" className='knowmore_cls'>Know more</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Aboutclinic