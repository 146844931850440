import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReactPlayer from 'react-player';


function Reviews() {

  const reviews = [
    {
      'name': 'Pavan Kalyan',
      'review': 'Dr. Silpa s practice is terrific. She combines expertise and a willingness to listen and discuss. Also, the staff is very friendly and professional.  She takes time with the patient to help them with their health issues! I highly recommend her to anyone looking for a specialist.'
    },
    {
      'name': 'Deepika Arangi',
      'review': 'Dr. Shilpahasa has been really helpful. She is very good at explaining the treatment and process. Appreciate doctor s patience while addressing all our queries. One of the best doctors and I would definitely recommend this hospital.'
    },
    {
      'name': 'Akash Røçky Bhäï',
      'review': 'We are glad that we met Dr Shilpa. She truly deserves all appreciation. She is very experienced, calm and friendly in nature. During my pregnancy she motivated me to stay active and eat healthy.'
    },
    {
      'name': 'Bujji Ippili kaa share',
      'review': 'Dr. Shlipa very friendly And helpful. I especially love how she really took time to explain my mother condition as well as treatment options. She performed Laparoscopic surgery on my mother And now she is doing well . So highly recommend this hospital.'
    },
    {
      'name': 'Leela Kunireddy',
      'review': 'Hi shilpa madam, we are very happy to contact you. We love the way you talk to patients. Along with the patients, the family person is also called and the whole problem is explained. They are making positive vibes in us by giving us the courage that no matter what the problem is, I am there for you. Treating the patient as a friend, they ask the problem and give treatment. Doctor, when we came into your room, you gave us courage with your smile. When we told you about our problem, you treated us with great care. Thank you so much madam. great effort madam. If anyone asks me if there is a known gyanacolagist, I will refer you first, madam. thank you once again Madam.'
    },
    {
      'name': 'Badda Krishna Rao',
      'review': 'Dr.Shilpa is the best, I have ever met. She’s  friendly and asks about our concerns and takes care like a friend. In my pregnancy I use to have lots of concerns n doubts. But Shilpa mam treats me like a friend and she use to give me a brief and clear explanation about everything which I’m going through. So, I’m really blessed to have her in my pregnancy period.'
    },
    {
      'name': 'Govindarajulu Sammala',
      'review': 'Words are less to thank Dr. Shilpa. My friend wife visited her for pregnancy. It was a normal delivery. She took care of all the detailings required for a smooth and safe delivery. She understands the mindset of the patient and clarifies all their doubts..thanks a lot'
    },
    {
      'name': 'manohar gunna',
      'review': 'We met dr.silpahasa madam for my wife s period issues and pregnancy concern.after we spoke to her.we felt very relaxed..the way she talks to us with a smile it self cures half the illness.highly recommend dr.silpahasa for all gynaecology issues.'
    },
    {
      'name': 'davuluri navya',
      'review': 'I m Navya, I went to Dr. Shilpa ma am with 103°F fever after 1 month of my baby delivery. She responded to me immediately and took care of me very well during my stay in the  hospital.'
    },
    {
      'name': 'RAMANA VASAPALLI',
      'review': 'Dr.silpa madam received me very well when I consulted her.she speaks with a smile at every consultation.i followed up with madam for my second pregnancy journey.dr.silpahasa is the best gynaecologist in Visakhapatnam.'
    },
    {
      'name': 'Latha Bondada',
      'review': 'I came from palasa, srikakulam for my endometriosis cyst surgery.madam did laparoscopic cyst removal.i recovered very fast and went back to my hometown in 1day.i didn t expect this much fast recovery.very happy with dr silpahasa.any one can consult dr.silpa for laparoscopic surgeries.she is the best gynaecologist.'
    },
  ]

  const videos = [
    {
      "title": '',
      "content": '',
      "link": 'https://www.youtube.com/embed/ivU8soAEHqw'
    }, {
      "title": '',
      "content": '',
      "link": 'https://www.youtube.com/embed/isUlyGAChqE'
    }, {
      "title": '',
      "content": '',
      "link": 'https://www.youtube.com/embed/Tx-plTiJn7A'
    }, {
      "title": '',
      "content": '',
      "link": 'https://www.youtube.com/embed/I9vkE3VMeZk'
    }, {
      "title": '',
      "content": '',
      "link": 'https://www.youtube.com/embed/asY2lx8FyYs'
    }, {
      "title": '',
      "content": '',
      "link": 'https://www.youtube.com/embed/tmeKNykSAq4'
    },
    {
      "title": '',
      "content": '',
      "link": 'https://www.youtube.com/embed/ebgyLnzLmDI'
    }
  ]


  return (
    <div>


      <div className="main-area">
        <div className="container">
          <div className="breadcrums py-1">
            <div className="row pt-4">
              <div className="col-auto col-md-10">
                <nav aria-label="breadcrumb " className="second">
                  <ol className="breadcrumb indigo lighten-6 first">
                    <li className="breadcrumb-item font-weight-bold "><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">Home</span></a><i className="fa fa-angle-double-right " aria-hidden="true" /></li>
                    <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">Gallery</span></a><i className="fa fa-angle-double-right text-uppercase " aria-hidden="true" /></li>
                    <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a active-2" href="#"><span>Reviews</span></a></li>
                  </ol>
                </nav>
              </div>

              <h1 className='Title_header'>Patients Reviews</h1>
            </div>
          </div>
        </div>

        <div className='container-fluid px-0'>

          <div className='container'>

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Reviews">

                <div id="Reviews" className='review_bg'>
                  <div className='mt-4 p-4'>

                    <div className='gview'>
                      <h3>Google Reviews</h3>
                    </div>

                    <div className='row'>

                      {reviews.map((review, index) => {

                        return (
                          <div className='col-md-4 mb-4' key={index}>
                            <div className='card min335 p-3'>
                              <div className='stars'>
                                <div className='start_cls'>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                  <i className='fa fa-star text-warning1 mx-1 fx-2'></i>
                                </div>

                                <div className='user'>
                                  <h5>{review.name}</h5>
                                </div>
                              </div>

                              <div className='customerimg my-3'>

                                <p>{review.review}</p>
                              </div>
                            </div>


                          </div>
                        )
                      })}

                    </div>
                  </div>
                </div>



              </Tab>

            </Tabs>



            <div className='bookappointment my-4'>
              <div class="row">
                <div className="col-md-8">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30389.986713842034!2d83.33862783476562!3d17.80352080000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395b008312d2a5%3A0xa175a392dd875e9a!2sDr.%20Silpa&#39;s%20Clinic%20%40%20Vaatsalya%20hospital%20%2C%20best%20obstetrician%20and%20gynaecologist%20in%20Visakhapatnam!5e0!3m2!1sen!2sin!4v1682766403972!5m2!1sen!2sin"
                    className="ifrmae2"
                    style={{ border: "0" }}
                    allowFullScreen
                    loading="lazy"
                  />
                </div>

                <div className="col-md-4">
                  <div className='formSubmit'>
                    <p>We Are Ready To Help You With A Smile!</p>
                    <h4 className='font-weight-bold'><i class="fa fa-cell"></i>Call Us: <span className='colorora'>+91 7095925747</span></h4>

                    <form>
                      <input type="text" placeholder="Fullname*" className='form-control mb-3' />

                      <input type="text" placeholder="Mobile*" className='form-control mb-3' />

                      <input type="text" placeholder="Email*" className='form-control mb-3' />

                      <select className='form-control mb-3'>
                        <option>Pregnancy</option>
                        <option>IVF</option>
                        <option>Infertility</option>
                      </select>
                      <div className='my-3'>
                        <textarea placeholder='Please tell us your concern in detail *' className='form-control my-3'>

                        </textarea>
                      </div>


                    </form>

                    <div className='my-3'>

                      <a href="" className='bookapp my-4 w-100'>Book Appointment</a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>




      </div>
    </div>
  )
}

export default Reviews