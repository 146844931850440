import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import axios from "axios";

export default function ImageGallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);


  useEffect(() => {
    axios.get("https://admin.drsilpahasa.com/api/v1/gallery").then((response) => {
      var oldJson=response.data;
      if(oldJson){
        var newJson = oldJson.map(image => {
          return {
            'src':image.path,
            'width': 5,
            'height': 3,
             }
          })
          setPhotos(newJson)
      }


    })
  }, [])
  console.log(photos)
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };



  return (
    <div className="container mt-5 mb-5 gallery">
      <h4 className="text-center">Image Gallery</h4>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox} data-backdrop="false">
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}
