import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Contact from "./Components/Aboutus/Contact";
import Aboutclinic from "./Components/Aboutus/Aboutclinic";
import Aboutdr from "./Components/Aboutus/Aboutdr";
import Blogs from "./Components/PatientGuide/Blogs";
import Reviews from "./Components/PatientGuide/Reviews";
import Videos from "./Components/PatientGuide/Videos";
import "./Components/Navbar.css";
import { useEffect, useState } from "react";
import Dynamic_page from "./Components/Dynamicpage/Dynamicpage";
import ImageGallery from "./Components/ImageGallery";
import Videosection from "./Components/Videosection";
import Terms_conditions from "./Components/terms_conditions";
import Privacy_policy from "./Components/privacy_policy";



function openNav() {
  document.getElementById("mySidebar").style.width = "280px";
  document.getElementById("main").style.marginLeft = "280px";
}

function closeNav() {
  document.getElementById("mySidebar").style.width = "0";
  document.getElementById("main").style.marginLeft = "0";
}


function App() {


  const [isMobile, setIsMobile] = useState(false)
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }// create an event listener
  useEffect(() => {

    window.addEventListener("resize", handleResize)
  })



  const menu = [
    {
      navitem: 'About me',
      submenu: [
        { title: 'About dr.silpahasa', link: '/Aboutdr' },
        { title: 'About clinic', link: '/Aboutclinic' },
        { title: 'Contact us', link: '/Contact' },
      ]
    },
    {
      navitem: 'Services',
      submenu: [
        { title: 'Maternity care', link: 'page/Maternity_care' },
        { title: 'Normal deliveries and cesarean sections', link: 'page/normal_vaginal_deliveries' },
        { title: 'Pregnancy and Gynaec ultra sound scans', link: '/page/ultrasound_in_pregnancy' },
        { title: 'Laparoscopy and hysteroscopy surgeries  surgeries', link: '/page/laparoscopy_hysteroscopy_surgeries ' },
        { title: 'Infertility treatment', link: '/page/infertility_treatment' },
        { title: 'Gynaecology problems ', link: '/page/gynaecology_problems_in_women' },
        { title: 'Hysterectomies & Ovarian cyst surgeries', link: '/page/hysterectomies_ovarian' },
        { title: 'Adolescent health', link: '/page/adolescent_health' },
        { title: 'Family planning services', link: '/page/family_planning_services' },
        { title: 'Post menopausal problems', link: '/page/post_menopausal_problems'},
        { title: 'Gynaecological cancers', link: '/page/gynaecological_cancers'},
        { title: 'Safe abortion services', link: '/page/safe_abortion_services' },
      ]
    },
    // {
    //   navitem: 'Facilites',
    //   submenu: [
    //     { title: '24*7 emergency services', link: '/page/24_7_emergency_services' },
    //     { title: 'Out patient and in patient services', link: '/page/Outpatient_Inpatient_Services' },
    //     { title: 'Ultrasound scans', link: '/page/ultrasound_scans' },
    //     { title: 'Operation theatres', link: '/page/operation_theatres' },
    //     { title: '24*7 lab and pharmacy', link: '/page/24_7_Laboratory_Pharmacy' },
    //   ]
    // },
    {
      navitem: 'Doctors',
      submenu: [
        { title: 'Fetal medicine specialist', link: '/page/mfm' },
        { title: 'Anaesthesiology and critical care specialists', link: '/page/Anaesthesiology_criticalcare' },
        { title: 'Paediatrician and neonatologist', link: '/page/paediatrician_neonatologist' },
        { title: 'Medical allied', link: '/page/allied_medical_specialists' },
        { title: 'Surgical allied', link: '/page/allied_surgical_specialists' },

        { title: 'Obstetrician and gynaecologist', link: '/page/type1' },
      ]
    },
    {
      navitem: 'Gallery',
      submenu: [
        { title: 'Photos', link: '/Gallery' },
        { title: 'Google reviews', link: '/Reviews' },
        { title: 'Videos', link: '/Videos' },
      ]
    },
    // {
    //   navitem: 'Patient Education',
    //   submenu: [
    //     { title: 'Maternity', link: '/page/type1' },
    //     { title: 'Gynaecology', link: '/page/type2' },
    //     { title: 'PCOS and adolescent health', link: '/page/pcod' },
    //     { title: 'Laparoscopy and hysteroscopy', link: '/page/laparoscopy_hysteroscopy' },
    //     { title: 'Infertility', link: '/page/infertility' },
    //     { title: 'Cancers', link: '/page/cancers' },
    //     { title: 'Contraception and safe sex', link: '/page/contraception_safe_sex_birth' },
    //   ]
    // },
    // {
    //   navitem: 'Videos',
    //   submenu: [
    //     { title: 'Pregnancy care and delivery', link: 'pregnancy_care_delivery' },
    //     { title: 'Gynaecologys', link: 'gynaecologys' },
    //     { title: 'Family planning and safe sex', link: 'family_planning_safe_sex' },
    //     { title: 'Fertility', link: 'fertility' },
    //     { title: 'Adolescent health& menstrual hygiene', link: 'health_wellness' },
    //     { title: 'Pcos', link: 'pcos' },
    //     { title: 'Health and wellness', link: 'health_wellness' },
    //   ]
    // },
  ]



  const maternity = [
    { title: 'Pre pregnancy care', link: '/page/prepregnancy_care' },
    { title: 'Pregnancy care', link: '/page/pregnancy_care' },
    { title: 'Ultrasound scans in pregnancy', link: '/page/ultrasound_scans_pregnancy' },
    { title: 'Vaccination in pregnancy', link: '/page/vaccination_pregnancy' },
    { title: 'The stages of labor and birth', link: '/page/stages_labour_birth' },
    { title: 'High risk pregnancy', link: '/page/high_risk_pregnancy' },
    { title: 'Caesarean delivery', link: '/page/cesarean_delivery' },
  ]
  const gynaecology = [
    { title: 'Endometriosis', link: '/page/endometriosis' },
    { title: 'Fibroids', link: '/page/uterine_fibroids' },
    { title: 'Heavy menstrual bleeding', link: '/page/heavy_menstrual_bleeding' },
    { title: 'Painful periods', link: '/page/dysmenorrhea' }
  ]



  return (
    <div className="App app-container">
      <Header />
      {isMobile ?
        <>
          <div id="mySidebar" className="sidebar">
            <a href="#" className="closebtn" onClick={closeNav}>
              ×
            </a>
            <ul className="ul-nav1">

              {menu.map((element, index) => {
                return (
                  <li className="nav-link  main-item">
                    <a href="#">
                      {element.navitem}
                      <i className="fas fa-caret-down" />
                    </a>

                    <div className="dropdown main_navitems">
                      <ul className="ul-nav2">

                        {element.submenu.map((subelement, subindex) => {
                          return (

                            subelement.title == 'Maternity' ?

                              <li className="dropdown-link">
                                <a href="#">
                                  {subelement.title}
                                  <i className="fas fa-caret-down" />
                                </a>
                                <div className="dropdown second">
                                  <ul>
                                    {maternity.map((maternity_item) => {
                                      return (
                                        <li className="dropdown-link">
                                          <Link to={maternity_item.link}>{maternity_item.title}</Link>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              </li>

                              :
                              subelement.title == 'Gynaecology' ?

                                <li className="dropdown-link">
                                  <a href="#">
                                    {subelement.title}
                                    <i className="fas fa-caret-down" />
                                  </a>
                                  <div className="dropdown second">
                                    <ul>
                                      {gynaecology.map((gynaecology_item) => {
                                        return (
                                          <li className="dropdown-link">
                                            <Link to={gynaecology_item.link}>{gynaecology_item.title}</Link>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </div>
                                </li>


                                : <li className="dropdown-link">
                                  <Link to={subelement.link}>
                                    {subelement.title}
                                  </Link>
                                </li>
                          )

                        })}

                      </ul>
                    </div>
                  </li>
                )

              })}

            </ul>
          </div>
          <div id="main1">
            <button className="openbtn" onClick={openNav}>
              <span className="hamburger-menu">☰</span> <span className="hamburger-text">Dr. Silpahasa samalla</span></button>
          </div>
        </>
        :
        <div className="headerSEct">
          <div className="header">
            <div className="container">
              <div className="nav-btn">
                <div className="nav-links">
                  <ul className="ul-nav1">

                    {/* lap */}
                    {menu.map((element, index) => {
                      return (
                        <li className="nav-link">
                          <a href="#">
                            {element.navitem}
                            <i className="fas fa-caret-down" />
                          </a>
                          <div className="dropdown main_navitems">
                            <ul className="ul-nav2">

                              {element.submenu.map((subelement, subindex) => {
                                return (

                                  subelement.title == 'Maternity' ?

                                    <li className="dropdown-link">
                                      <a href="#">
                                        {subelement.title}
                                        <i className="fas fa-caret-down" />
                                      </a>
                                      <div className="dropdown second">
                                        <ul>
                                          {maternity.map((maternity_item) => {
                                            return (
                                              <li className="dropdown-link">
                                                <Link to={maternity_item.link}>{maternity_item.title}</Link>
                                              </li>
                                            )
                                          })}
                                        </ul>
                                      </div>
                                    </li>

                                    :
                                    subelement.title == 'Gynaecology' ?

                                      <li className="dropdown-link">
                                        <a href="#">
                                          {subelement.title}
                                          <i className="fas fa-caret-down" />
                                        </a>
                                        <div className="dropdown second">
                                          <ul>
                                            {gynaecology.map((gynaecology_item) => {
                                              return (
                                                <li className="dropdown-link">
                                                  <Link to={gynaecology_item.link}>{gynaecology_item.title}</Link>
                                                </li>
                                              )
                                            })}
                                          </ul>
                                        </div>
                                      </li>


                                      : <li className="dropdown-link">
                                        <Link to={subelement.link}>
                                          {subelement.title}
                                        </Link>
                                      </li>
                                )

                              })}

                            </ul>
                          </div>
                        </li>
                      )

                    })}

                    {/* end lap */}

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      }



      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Aboutclinic" element={<Aboutclinic />} />
        <Route path="/Aboutdr" element={<Aboutdr />} />
        <Route path="/Videos" element={<Videos />} />
        <Route path="/Reviews" element={<Reviews />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Aboutdr" element={<Aboutdr />} />
        <Route path="/Gallery" element={<ImageGallery />} />
        <Route path="/page/:pagename" element={<Dynamic_page />} />
        <Route path="/pregnancy_care_delivery" element={<Videosection />} />
        <Route path="/gynaecologys" element={<Videosection />} />
        <Route path="/family_planning_safe_sex" element={<Videosection />} />
        <Route path="/fertility" element={<Videosection />} />
        <Route path="/adolescent_health" element={<Videosection />} />
        <Route path="/pcos" element={<Videosection />} />
        <Route path="/health_wellness" element={<Videosection />} />
        <Route path="/terms_conditions" element={<Terms_conditions />} />
        <Route path="/privacy_policy" element={<Privacy_policy />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
