import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Blog1 from '../../assets/Gyanecology/blog1.png';
import Blog2 from '../../assets/Gyanecology/blog2.png';
import Blog3 from '../../assets/Gyanecology/blog3.png';
import Gynaecology from '../../assets/Gyanecology/Gynaecology.jpg';
import G1 from '../../assets/Gyanecology/g1.jpg';
import G2 from '../../assets/Gyanecology/g2.webp';
import G3 from '../../assets/Gyanecology/g3.jpg';
import G4 from '../../assets/Gyanecology/g7.jpg';
import G5 from '../../assets/Gyanecology/g1.jpg';
import G6 from '../../assets/Gyanecology/g6.jpg';
import G7 from '../../assets/Gyanecology/g7.jpg';
import I1 from '../../assets/Infertility/I1.jpg';
import I2 from '../../assets/Infertility/I2.jpg';
import I4 from '../../assets/Infertility/I4.png';
import I5 from '../../assets/Infertility/I5.jpg';
import I6 from '../../assets/Infertility/I6.jpg';
import I7 from '../../assets/Infertility/I7.jpg';
import I8 from '../../assets/Infertility/I8.png';
import I3 from '../../assets/Infertility/I3.jpg';
import M1 from '../../assets/Maternity/m1.jpg';
import M2 from '../../assets/Maternity/m2.jpg';
import M3 from '../../assets/Maternity/m3.jpg';
import M4 from '../../assets/Maternity/m4.jpg';
import L1 from '../../assets/Maternity/L1.jpg';
import L2 from '../../assets/Maternity/L2.jpg';
import L3 from '../../assets/Maternity/L3.jpg';
import L4 from '../../assets/Maternity/L4.jpg';
import L5 from '../../assets/Maternity/L5.jpg';
import P1 from '../../assets/Maternity/P1.jpg';
import P2 from '../../assets/Maternity/P2.jpg';
import P3 from '../../assets/Maternity/P3.jpg';
import P4 from '../../assets/Maternity/P4.jpg';
import P5 from '../../assets/Maternity/P5.png';
import P6 from '../../assets/Maternity/P6.png';
import ReactPlayer from 'react-player';
function Blogs() {
  return (
    <div>


      <div className="main-area">
        <div className="container">
          <div className="breadcrums py-4">
            <div className="row pt-4">
              <div className="col-auto col-md-10">
                <nav aria-label="breadcrumb " className="second">
                  <ol className="breadcrumb indigo lighten-6 first">
                    <li className="breadcrumb-item font-weight-bold "><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">Home</span></a><i className="fa fa-angle-double-right " aria-hidden="true" /></li>
                    <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a" href="#"><span className="mr-md-3 mr-2">Patient Guide</span></a><i className="fa fa-angle-double-right text-uppercase " aria-hidden="true" /></li>
                    <li className="breadcrumb-item font-weight-bold"><a className="black-text text-uppercase bread_a active-2" href="#"><span>Blogs</span></a></li>
                  </ol>
                </nav>
              </div>

              <h1 className='Title_header'>Our Treatment Blogs</h1>
            </div>
          </div>
        </div>

        <div className='container px-0'>

          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Gyaecology" title="Gyanecology">
              <div className='conatiner-fluid'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={Blog1} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Observations</a>
                          <span className="post-date">March 27, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={Blog2} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Blood Tests</a>
                          <span className="post-date">December 28, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={Blog3} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Meidcations</a>
                          <span className="post-date">April 27, 2019</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={G7} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={G7} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={Gynaecology} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={G6} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={G7} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Infertility" title="Infertility">
              <div className='conatiner-fluid'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={I1} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Observations</a>
                          <span className="post-date">March 27, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={I2} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Blood Tests</a>
                          <span className="post-date">December 28, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={I3} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Meidcations</a>
                          <span className="post-date">April 27, 2019</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={I4} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={I5} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={I6} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={I7} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={I8} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Maternity" title="Maternity">
              <div className='conatiner-fluid'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={M1} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Observations</a>
                          <span className="post-date">March 27, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={M2} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Blood Tests</a>
                          <span className="post-date">December 28, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={M3} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Meidcations</a>
                          <span className="post-date">April 27, 2019</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={M4} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>














                </div>
              </div>
            </Tab>

            <Tab eventKey="Laparoscopic Surgery" title="Laparoscopic Surgery">
              <div className='conatiner-fluid'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={L1} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Observations</a>
                          <span className="post-date">March 27, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={L2} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Blood Tests</a>
                          <span className="post-date">December 28, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={L3} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Meidcations</a>
                          <span className="post-date">April 27, 2019</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={L4} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={L1} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={L2} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={L4} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={L5} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Laparoscopic" title="Laparoscopic">
              <div className='conatiner-fluid'>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={P1} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Observations</a>
                          <span className="post-date">March 27, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={P2} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Blood Tests</a>
                          <span className="post-date">December 28, 2018</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={P3} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Meidcations</a>
                          <span className="post-date">April 27, 2019</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={P4} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={P5} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={P6} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>



                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={P3} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>


                  <div className='col-md-3'>
                    <div className="post">
                      <a className="post-img" href="blog-post.html">
                        <img src={P2} className="w-100" />
                      </a>
                      <div className="post-body">
                        <div className="post-meta">
                          <a className="post-category cat-1" href="category.html">Gyanecology Tests</a>
                          <span className="post-date">May 27, 2022</span>
                        </div>
                        <h3 className="post-title">
                          <a href="blog-post.html" className='post_a'>
                            Pagedraw UI Builder Turns Your Website Design Mockup Into Code Automatically
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>








          <div className='bookappointment my-4'>
            <div class="row">
              <div className="col-md-8">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30389.986713842034!2d83.33862783476562!3d17.80352080000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a395b008312d2a5%3A0xa175a392dd875e9a!2sDr.%20Silpa&#39;s%20Clinic%20%40%20Vaatsalya%20hospital%20%2C%20best%20obstetrician%20and%20gynaecologist%20in%20Visakhapatnam!5e0!3m2!1sen!2sin!4v1682766403972!5m2!1sen!2sin"
                  className="ifrmae2"
                  style={{ border: "0" }}
                  allowFullScreen
                  loading="lazy"
                />
              </div>

              <div className="col-md-4">
                <div className='formSubmit'>
                  <p>We Are Ready To Help You With A Smile!</p>
                  <h4 className='font-weight-bold'><i class="fa fa-cell"></i>Call Us: <span className='colorora'>+91 7095925747</span></h4>

                  <form>
                    <input type="text" placeholder="Fullname*" className='form-control mb-3' />

                    <input type="text" placeholder="Mobile*" className='form-control mb-3' />

                    <input type="text" placeholder="Email*" className='form-control mb-3' />

                    <select className='form-control mb-3'>
                      <option>Pregnancy</option>
                      <option>IVF</option>
                      <option>Infertility</option>
                    </select>
                    <div className='my-3'>
                      <textarea placeholder='Please tell us your concern in detail *' className='form-control my-3'>

                      </textarea>
                    </div>


                  </form>

                  <div className='my-3'>

                    <a href="" className='bookapp my-4 w-100'>Book Appointment</a>

                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>




      </div>



    </div>
  )
}

export default Blogs